import React, { useState, useEffect, useContext } from "react";
import "../css/home.css";
import Card from './Card';
import Map from './Map';
import HaversineGeolocation from 'haversine-geolocation';
import axios from 'axios';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Spinner from "./Spinner";
import UserContext from './../UserContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import eventBus from "../EventBus";

function Home() {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    var [IsLoaded, setIsLoaded] = useState(false);

    const [searchTimeout, setSearchTimeout] = useState(null);
    const [isSearching, setIsSearching] = useState(true);
    const token = localStorage.getItem("jwt");

    const [currentLocation, setCurrentLocation] = useState(null);
    const [companies, setCompanies] = useState([])
    const [searchString, setSearchString] = useState('')
    const [scrolledValue, setScrolledValue] = useState('')
    const [istoggled, setIstoggled] = useState(false)
    const [options] = useState([
        {
            label: 'Statut', options: [
                { value: 'open', label: 'Ouvert uniquement' },
            ]
        },
        {
            label: 'Type de paiement', options: [
                { value: 'card', label: 'Carte' },
                { value: 'coin', label: 'Pièce' },
                { value: 'ticket', label: 'Billet' },
                { value: 'fidelity', label: 'Fidélité' },
                { value: 'token', label: 'Jeton' }
            ]
        },
        {
            label: 'Equipement disponible', options: [
                { value: 'parking', label: 'Parking' },
            ]
        }
    ]);
    const animatedComponents = makeAnimated();
    const [selectedFilter, setSelectedFilter] = useState([])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function (positionUser) {
                var pointUser = {
                    latitude: positionUser.coords.latitude,
                    longitude: positionUser.coords.longitude
                }
                setCurrentLocation(pointUser)
            },
            function (error) {
                setCurrentLocation(false)
            }
        )

        window.onscroll = function (e) {
            setScrolledValue(window.scrollY)
        }

        if (currentLocation === false || companies.length === 0) {
            setIsLoaded(true)
        }

        if (currentLocation !== null && currentLocation !== false) {
            for (let i = companies.length - 1; i >= 0; i -= 1) {

                companies[i].distance = null
                companies[i].position = [companies[i].latitude, companies[i].longitude]
                //

                if (companies[i].distance === null) {
                    var pointLaundry = {
                        latitude: companies[i].latitude,
                        longitude: companies[i].longitude
                    }
                    companies[i].distance = (HaversineGeolocation.getDistanceBetween(currentLocation, pointLaundry))

                }

                if (i === companies.length - 1) {
                    setIsLoaded(true)
                    setCompanies(companies)
                }
            }
        }

        eventBus.on('selectedFilter', setSelectedFilter);
        eventBus.on('searchString', setSearchString);
        eventBus.on('centerLaundry', setIstoggled);

        return () => {
            eventBus.off('selectedFilter', setSelectedFilter);
            eventBus.off('searchString', setSearchString);
            eventBus.off('centerLaundry', setIstoggled);
        };
    }, []);

    useEffect(() => {
        if (currentLocation !== null) {
            getCompaniesList()
        }
    }, [currentLocation]);

    useEffect(() => {
        if (searchTimeout) clearTimeout(searchTimeout);

        setSearchTimeout(
            setTimeout(() => {
                search(searchString);
            }, 500)
        );
    }, [searchString]);

    async function getCompaniesList() {

        await axios.get(process.env.REACT_APP_API_URL + 'laundriesList', {
            params: { position: currentLocation },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (currentLocation !== false) {
                    response.data.map((company, index) => {
                        var pointLaundry = {
                            latitude: company.latitude,
                            longitude: company.longitude
                        }
                        company.distance = (HaversineGeolocation.getDistanceBetween(currentLocation, pointLaundry))
                    })
                }
                setCompanies(response.data)
                setIsSearching(false);
            }).catch(err =>
                err
            );
    }

    let companiesOrderByDistance = [...companies].sort((a, b) => a.distance - b.distance);

    function search(value) {

        if (value == "") {
            getCompaniesList()
        } else {
            setIsSearching(true);
            axios.post(process.env.REACT_APP_API_URL + 'filterLaundries', { searchString: value, filters: selectedFilter })
                .then(response => {
                    if (currentLocation !== false) {
                        response.data.map((company, index) => {
                            var pointLaundry = {
                                latitude: company.latitude,
                                longitude: company.longitude
                            }
                            company.distance = (HaversineGeolocation.getDistanceBetween(currentLocation, pointLaundry))
                        })
                    }

                    setCompanies(response.data)
                    setIsSearching(false);
                }).catch(err =>
                    setIsSearching(false)
                );
        }

    }

    function scrollToTop() {
        window.scroll(0, 0);
    }

    function scrollToCurrentElement(id) {

        var cardElement = document.getElementById(id)
        var cardElementPosition = cardElement.getBoundingClientRect()

        var mapCollapse = document.getElementById('map-button')

        if (istoggled) {
            window.scrollBy(0, cardElementPosition.top - 425);
        } else {
            window.scrollBy(0, cardElementPosition.top - 115);
        }

    }

    function updateFilters(event) {
        var selectedElement = []
        var filters = event.target.selectedOptions
        for (let filter of filters) {
            selectedElement.push(filter.value)
        }

        setSelectedFilter(selectedElement)
    }

    if (IsLoaded === false) {
        return
        <>
            <div className="fixed-top" style={{ zIndex: '9' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave2_Primary waveColorsSecondary"></path>
                    <path className="wave2_Secondary waveColorsMain"></path>
                </svg>
            </div>
            <Spinner style={{ zIndex: '10' }} />
        </>;
    }

    if (IsLoaded === true) {
        return (
            <>
                <div className="fixed-top" style={{ zIndex: '9' }}>
                    <svg viewBox="0 0 500 300">
                        <path className="wave2_Primary waveColorsSecondary"></path>
                        <path className="wave2_Secondary waveColorsMain"></path>
                    </svg>
                </div>

                <div className="row mx-0">
                    <div className={istoggled ? "map-volet map-open" : "map-volet"} style={{ zIndex: '11', backgroundColor: '#272727' }}>

                        <div className="mt-5 mt-xl-0 col-lg-6 border-top fixed-lg-top" style={{ zIndex: '11', height: '300px' }}>
                            {istoggled &&
                                <Map companies={companies} selectedFilter={selectedFilter} />
                            }
                        </div>

                        <button id="map-button" type="button" className="w-100 border-0 p-0 my-2 pb-2 text-white border-bottom bg-transparent" style={{ zIndex: '11' }} onClick={() => setIstoggled(!istoggled)}>
                            Map {istoggled ?
                                <FontAwesomeIcon icon="fa-solid fa-chevron-up" /> :
                                <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                            }
                        </button>

                    </div>

                    <div className={istoggled ? "col-md-6 px-3 mt-md-5 pt-md-3 item-list map-open" : "col-md-6 px-3 mt-md-5 pt-md-3 item-list"}>
                        <div className="row search-in-home">
                            <div className={user === null ? "col-6 col-lg-8 mb-3" : "col-6 col-lg-7 mb-3"}>
                                <input
                                    type="text"
                                    style={{ position: "relative" }}
                                    className="form-control"
                                    placeholder="Recherche..."
                                    value={searchString}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setSearchString(value);

                                        if (searchTimeout) clearTimeout(searchTimeout);

                                        setSearchTimeout(
                                            setTimeout(() => {
                                                search(value);
                                            }, 500)
                                        );
                                    }}
                                />
                            </div>

                            <div className={window.innerWidth >= 426 ? "col-6 col-lg-4 mb-3 " : "col-6 col-lg-4 mb-3 d-flex align-items-center"} >

                                {window.innerWidth > 426 ?
                                    <Select
                                        isMulti={true}
                                        options={options}
                                        classNamePrefix="select"
                                        closeMenuOnSelect={false}
                                        components={makeAnimated()}
                                        placeholder="Filtres"
                                        onChange={(e) => setSelectedFilter(e.map((item) => item.value))}
                                    />

                                    :

                                    <select id="multiSelect" className="custom-select w-100 h-100 rounded-2" multiple onChange={(e) => updateFilters(e)}>
                                        <option disabled selected hidden>Filtres...</option>
                                        {options.map((optgroup, optgroupIndex) => (
                                            <optgroup key={optgroupIndex} label={optgroup.label}>
                                                {optgroup.options.map((option, optionIndex) => (
                                                    <option key={optionIndex} value={option.value}>{option.label}</option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                }

                            </div>
                        </div>
                        <div className="row">
                            {isSearching && <Spinner />}
                            {companiesOrderByDistance.length === 0 && <p>Aucune laverie n'a été trouveé près de chez vous</p>}
                            {!isSearching && companiesOrderByDistance.map((company, index) => {
                                return (
                                    <div id={index} key={index} onClick={() => scrollToCurrentElement(index)}>
                                        <Card company={company} selectedFilter={selectedFilter} />
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
                {scrolledValue >= 1 &&
                    <button className="fixed-bottom start-50 translate-middle-x mb-3 opacity-75 rounded-3" style={{ zIndex: 10, width: 'fit-content', backgroundColor: 'white', borderColor: 'var(--primary-color)' }} onClick={() => scrollToTop()}>
                        <FontAwesomeIcon className="opacity-100" icon="fa-solid fa-angles-up" color={"var(--primary-color)"} size="2xl" />
                    </button>
                }
            </>
        );
    }
}

export default Home;
