import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import logo from '../img/laundry-map-logo.png';
import eventBus from "../EventBus";

function Menu() {
    const navigate = useNavigate();

    function logout() {
        localStorage.clear();
        eventBus.emit('setAuthUser', null);
        
        navigate("/");
    }

    return (
        <>
            <div style={{ paddingTop: '3.5rem' }}></div>
            <div className="position-absolute w-100" style={{ zIndex: '0' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave2_Primary waveColorsSecondary"></path>
                    <path className="wave2_Secondary waveColorsMain"></path>
                </svg>
            </div>
            <div className="d-flex flex-column align-items-center" style={{ zIndex: '1' }}>
                <h1 className="mb-5 z-index text-white main-ff">Mon compte</h1>
                <img src={logo} className="modal-logo rounded-4 z-index" alt="logo" />
            </div>

            <div className='d-flex flex-column align-items-center justify-content-around mt-5 main-ff ' style={{ height: '12rem' }}>
                <button type='button' style={{ backgroundColor: 'var(--tertiary-color)', color: 'white' }} className="btn rounded-pill w-75 mb-3 fs-5" onClick={() => navigate('/laundries-form')}>Ajouter une Laverie</button>
                <button type='button' style={{ backgroundColor: 'var(--tertiary-color)', color: 'white' }} className="btn rounded-pill w-75 mb-3 fs-5" onClick={() => navigate('/laundries-user-list')}>Mes Laveries</button>
                <button type='button' style={{ backgroundColor: 'var(--tertiary-color)', color: 'white' }} className="btn rounded-pill w-75 fs-5" onClick={() => logout()}>Se déconnecter</button>

            </div>
            <div>
                <p className='position-absolute start-50 translate-middle-x' style={{ top: '92%', zIndex: '1', color: 'var(--primary-color)' }}>
                    Qui sommes nous ?
                </p>
            </div>
            <div className="reverse position-absolute bottom-0 w-100" style={{ zIndex: '0' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave1_Primary waveColorsSecondary"></path>
                    <path className="wave1_Secondary waveColorsMain"></path>
                </svg>
            </div>
        </>
    );
}

export default Menu;