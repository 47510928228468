import React, { useEffect, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../css/nav.css";
import UserContext from "../UserContext";
import { useNavigate } from 'react-router-dom';
import eventBus from "../EventBus";
import "../css/home.css";
import HaversineGeolocation from 'haversine-geolocation';
import axios from 'axios';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import "../css/UserListCard.css";

function Nav() {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    function profil() {
        navigate('/user-profil')
    }

    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    var [IsLoaded, setIsLoaded] = useState(false);

    const [searchTimeout, setSearchTimeout] = useState(null);
    const [isSearching, setIsSearching] = useState(true);

    const [currentLocation, setCurrentLocation] = useState(null);
    const [companies, setCompanies] = useState([])
    const [companiesBuffer, setCompaniesBuffer] = useState([])
    const [currentUser, setCurrentUser] = useState({ id: null })
    const [searchString, setSearchString] = useState('')
    const token = localStorage.getItem("jwt");
    const [options, setOptions] = useState([
        {
            label: 'Statut', options: [
                { value: 'open', label: 'Ouvert uniquement' },
                // { value: 'close', label: 'Fermé' }
            ]
        },
        {
            label: 'Type de paiement', options: [
                { value: 'card', label: 'Carte' },
                { value: 'coin', label: 'Pièce' },
                { value: 'ticket', label: 'Billet' },
                { value: 'fidelity', label: 'Fidélité' },
                { value: 'token', label: 'Jeton' }
            ]
        },
        {
            label: 'Equipement disponible', options: [
                { value: 'parking', label: 'Parking' },
            ]
        }
    ]);
    const [selectedFilter, setSelectedFilter] = useState([])

    useEffect(() => {
        eventBus.emit('selectedFilter', selectedFilter);
    }, [selectedFilter]);
    
    useEffect(() => {
        eventBus.emit('searchString', searchString);
    }, [searchString]);

    useEffect(() => {
        if (user !== null) {
            setOptions([
                {
                    label: 'Statut', options: [
                        { value: 'open', label: 'Ouvert uniquement' },
                    ]
                },
                {
                    label: 'Type de paiement', options: [
                        { value: 'card', label: 'Carte' },
                        { value: 'coin', label: 'Pièce' },
                        { value: 'ticket', label: 'Billet' },
                        { value: 'fidelity', label: 'Fidélité' },
                        { value: 'token', label: 'Jeton' }
                    ]
                },
                {
                    label: 'Equipement disponible', options: [
                        { value: 'parking', label: 'Parking' },
                    ]
                }
            ]);
        } else {
            setOptions([
                {
                    label: 'Statut', options: [
                        { value: 'open', label: 'Ouvert uniquement' }
                    ]
                },
                {
                    label: 'Type de paiement', options: [
                        { value: 'card', label: 'Carte' },
                        { value: 'coin', label: 'Pièce' },
                        { value: 'ticket', label: 'Billet' },
                        { value: 'fidelity', label: 'Fidélité' },
                        { value: 'token', label: 'Jeton' }
                    ]
                },
                {
                    label: 'Equipement disponible', options: [
                        { value: 'parking', label: 'Parking' },
                    ]
                }
            ]);
        }
    }, [user]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function (positionUser) {
                var pointUser = {
                    latitude: positionUser.coords.latitude,
                    longitude: positionUser.coords.longitude
                }
                setCurrentLocation(pointUser)
            },
            function (error) {
                setCurrentLocation(false)
            }
        )
    }, []);

    useEffect(() => {
        if (currentLocation !== null) {
            getCompaniesList()
        }
    }, [currentLocation]);

    useEffect(() => {
        if (currentLocation === false || companies.length === 0) {
            setIsLoaded(true)
        }

        if (currentLocation !== null && currentLocation !== false) {
            for (let i = companies.length - 1; i >= 0; i -= 1) {

                companies[i].distance = null
                companies[i].position = [companies[i].latitude, companies[i].longitude]
                //

                if (companies[i].distance === null) {
                    var pointLaundry = {
                        latitude: companies[i].latitude,
                        longitude: companies[i].longitude
                    }
                    companies[i].distance = (HaversineGeolocation.getDistanceBetween(currentLocation, pointLaundry))

                }

                if (i === companies.length - 1) {
                    setIsLoaded(true)
                    setCompanies(companies)
                }
            }
        }
    })

    async function getCompaniesList() {

        await axios.get(process.env.REACT_APP_API_URL + 'laundriesList', {
            params: { position: currentLocation },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (currentLocation !== false) {
                    response.data.map((company, index) => {
                        var pointLaundry = {
                            latitude: company.latitude,
                            longitude: company.longitude
                        }
                        company.distance = (HaversineGeolocation.getDistanceBetween(currentLocation, pointLaundry))
                    })
                }
                setCompanies(response.data)
                setCompaniesBuffer(response.data)
                setIsSearching(false);
            }).catch(err =>
                err
            );
    }

    function search(value) {

        if (value == "") {
            getCompaniesList()
        } else {
            setIsSearching(true);
            axios.post(process.env.REACT_APP_API_URL + 'filterLaundries', { searchString: value, filters: selectedFilter })
                .then(response => {
                    if (currentLocation !== false) {
                        response.data.map((company, index) => {
                            var pointLaundry = {
                                latitude: company.latitude,
                                longitude: company.longitude
                            }
                            company.distance = (HaversineGeolocation.getDistanceBetween(currentLocation, pointLaundry))
                        })
                    }

                    setCompanies(response.data)
                    setIsSearching(false);
                }).catch(err =>
                    setIsSearching(false)
                );
        }

    }

    return (
        <nav className="navbar">
            <div className='d-flex justify-content-between w-100 ' style={{zIndex:'10', backgroundColor: 'linear-gradient(145deg, rgba(255,217,59,1) 0%, rgba(255,141,47,1) 75%, rgba(255,141,47,1) 92%)'}}>
                <div className="navbar__title main-ff" onClick={() => navigate('/')}>{process.env.REACT_APP_PROJECT_NAME}</div>
                <div className='row w-75 search-in-nav'>
                    <div className=" offset-2 col-4 mb-3">
                        <input
                            type="text"
                            style={{ position: "relative" }}
                            className="form-control"
                            placeholder="Recherche..."
                            value={searchString}
                            onChange={(event) => {
                                const value = event.target.value;
                                setSearchString(value);

                                if (searchTimeout) clearTimeout(searchTimeout);

                                setSearchTimeout(
                                    setTimeout(() => {
                                        search(value);
                                    }, 500)
                                );
                            }}
                        />
                    </div>
                    <div className="col-2 mb-3">
                        <Select
                            isMulti={true}
                            options={options}
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            components={makeAnimated()}
                            placeholder="Filtres"
                            onChange={(e) => setSelectedFilter(e.map((item) => item.value))}
                        />
                    </div>
                </div>
                
                <button type='button' style={{backgroundColor: 'var(--secondary-color)'}} onClick={user !== null ? () => navigate('/menu') : () => navigate('/login-form')}>
                    <FontAwesomeIcon icon="fa-solid fa-bars" color='white'/>
                </button>
            </div>
        </nav>
    );
}

export default Nav;
