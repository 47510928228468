import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultLogo from '../img/logo-laverie.png'

import ProductLogo from '../img/product.png'
import DryerLogo from '../img/dryier.png'
import WashingMachine from '../img/washingMachine.png'
import OthersLogo from '../img/others.png'

import { useLocation } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "../css/info.css";
import { Tooltip as Tooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';

function Info() {
    const navigate = useNavigate()
    const { company } = useLocation().state
    const [mapType, setMapType] = useState('standard'); // 'standard' ou 'satellite'
    let currentDay = ''

    // Informations sur la laverie (à remplir avec les données spécifiques)
    const laundryInfo = {
        nom: company.name,
        telephone: company.phone_number.replace(/(\d{2})(?=\d)/g, '$1 '),
        email: company.email,
        description: company.description,
        localisation: company.city.charAt(0).toUpperCase() + company.city.slice(1),
        postalCode: company.postal_code,
        adresse: company.number + ' ' + company.street,
        website: '',
        payments: company.payments.map(payments => payments.label),
        equipements: company.equipements.map(equipement => equipement.label),
        logo: company.logo.file !== undefined ? company.logo.file : DefaultLogo,
        photos: company.files.map(file => file.content.file),
        horaires: buildResultString(company.hours),
        reseauxSociaux: company.links,

        machines: company.machines.map(machine => {
            let hours = 0;
            let remainingMinutes = 0;
            let timeInMinutes = 0;
            if (machine.duration != null) {
                const withoutTimezone = machine.duration.substring(0, machine.duration.indexOf('+'));
                const date = new Date(withoutTimezone);
                let timeInMinutes = (date.getTime() + 60000 * 60) / 60000;

                hours = Math.floor(timeInMinutes / 60);
                remainingMinutes = timeInMinutes % 60;
            }

            let duration = 0;
            if (hours === 0) {
                duration = `${timeInMinutes} Minute${timeInMinutes > 1 ? 's' : ''}`;
            } else if (remainingMinutes === 0) {
                duration = `${hours} Heure${hours > 1 ? 's' : ''}`;
            } else {
                duration = `${hours} Heure${hours > 1 ? 's' : ''} et ${remainingMinutes} Minute${remainingMinutes > 1 ? 's' : ''}`;
            }

            return {
                nom: machine.name,
                categorie: machine.category,
                details: machine.detail,
                duree: duration,
                prixMin: machine.minPrice.toFixed(2) + '€',
                prixMax: machine.maxPrice.toFixed(2) + '€',
                nombre: machine.count,
            }
        }
        )
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    company.links.forEach(link => {
        if (link.type === "Site Personnel") {
            laundryInfo.website = link.link
        }
    })

    function buildRanges(joursSelectionnes) {
        const joursSemaineOrdre = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        const joursRanges = [];

        joursSemaineOrdre.forEach((jour, index) => {
            if (joursSelectionnes.includes(jour)) {
                joursRanges[index] = jour
            }
        })

        return joursRanges;
    }
    function formatTime(hourMinute) {
        const [hour, minute] = hourMinute.split(':');
        return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    }
    function buildResultString(schedules) {
        const joursSemaine = {
            Lundi: 1,
            Mardi: 2,
            Mercredi: 4,
            Jeudi: 8,
            Vendredi: 16,
            Samedi: 32,
            Dimanche: 64,
        };

        const joursSemaineOrdre = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        const result = [];
        let joursRange = [];
        let horaire = '';


        for (const schedule of schedules) {
            const joursSelectionnes = joursSemaineOrdre.filter(jour => (schedule.day & joursSemaine[jour]) !== 0);
            joursRange = buildRanges(joursSelectionnes);
            horaire = `De ${formatTime(schedule.start_hour)} à ${formatTime(schedule.end_hour)}`;
        }
        joursRange.forEach(jour => {
            result.push({ jour: jour, horaire: horaire });
        });
        const ordreSemaine = {
            Lundi: 1,
            Mardi: 2,
            Mercredi: 3,
            Jeudi: 4,
            Vendredi: 5,
            Samedi: 6,
            Dimanche: 7,
        };
        const now = new Date();
        for (var key in ordreSemaine) {
            if (ordreSemaine[key] === now.getDay()) {
                currentDay = key;
            }
        };

        return result;
    }

    const googleItinerary = () => {
        const location = encodeURI(company.number + "," + company.street + "," + company.postal_code + " " + company.city);
        const url = `https://www.google.com/maps/dir/?api=1&destination=${location}`;

        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
    }

    return (
        <div className='px-4 info' style={{ color: 'white' }}>
            {/* En-tête de la laverie */}
            <div className='row mb-3'>
                <div className='col-8 align-self-center'>
                    <h2>{laundryInfo.nom}</h2>
                    <p className={company.statut === "open" ? 'opened-tag' : 'closed-tag'}>{company.statut === "open" ? 'Ouvert' : 'Fermé'}</p>
                </div>
                <div className='col-4 ms-auto'>
                    <img src={laundryInfo.logo} alt={laundryInfo.nom} style={{ maxWidth: '90px', borderRadius: '.7rem' }} />
                </div>
            </div>
            {laundryInfo.photos.length !== 0 &&
                <div className="col-md-6 my-3 mx-auto">
                    <div id="photoCarousel" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner ">
                            {laundryInfo.photos.length !== 0 &&
                                laundryInfo.photos.map((photo, index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                        <img
                                            src={photo}
                                            className="d-block w-100  carousel-image"
                                            alt={`Photo ${index}`}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#photoCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#photoCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            }
            <div className='d-flex justify-content-between align-items-center'>
                <div className='phone-info p-2 col-8 mb-3'>
                    <FontAwesomeIcon icon="fa-solid fa-phone" color={"white"} size={'lg'} />
                    <a href={"tel:+" + laundryInfo.telephone} className='ms-3'>Appeler la laverie</a>
                </div>
                {laundryInfo.reseauxSociaux.length !== 0 && (
                    <div className="col-md-4 text-center">
                        <ul className="list-inline">
                            {laundryInfo.reseauxSociaux.map((res) => (
                                <li key={res.type} className="list-inline-item">
                                    <a href={res.link} target="blank" rel="noopener noreferrer" className='ml-3' style={{ color: '#B2B2B2' }}>
                                        {res.type === "Instagram" && <FontAwesomeIcon icon="fa-brands fa-square-instagram" size={'xl'} />}
                                        {res.type === "Facebook" && <FontAwesomeIcon icon="fa-brands fa-square-facebook" size={'xl'} />}
                                        {res.type === "Site Personnel" && <FontAwesomeIcon icon="fa-solid fa-globe" size={'xl'} />}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {laundryInfo.description !== '' &&
                <div>
                    <h2 className='fs-6' style={{ color: 'var(--primary-color)' }}>Présentation</h2>
                    <p className='fs-5'>{laundryInfo.description}</p>
                </div>
            }

            {/* Informations */}

            <div className='mb-3'>
                <h4 className='fs-6' style={{ color: 'var(--primary-color)' }}>Coordonnées</h4>
                <div className={'location-info bottom-border'}>
                    <p className='mb-0'>{laundryInfo.email}</p>
                </div>
                <div className={'location-info bottom-border'}>
                    <p className='mb-0'>{laundryInfo.adresse + ','}</p>
                    <p>{laundryInfo.postalCode + ' ' + laundryInfo.localisation}</p>
                </div>
            </div>
            <div className='row'>
                <div className="mb-3">
                    <h4 className={'fs-6'} style={{ color: 'var(--primary-color)' }}>Horaires</h4>
                    {laundryInfo.horaires.map((horaire, index) => (
                        <div className='d-flex justify-content-between bottom-border' key={index}>
                            <p className={currentDay === horaire.jour ? 'mb-0 fw-bold' : 'mb-0'}>{horaire.jour}</p>
                            <p className={currentDay === horaire.jour ? 'mb-0 fw-bold' : 'mb-0'}>{horaire.horaire}</p>
                        </div>
                    ))}
                </div>

                <h5 className='fs-6 mb-4' style={{ color: 'var(--primary-color)' }}>Moyens de paiements</h5>
                <div className='equipement-list mb-3'>

                    <div>
                        <div data-tooltip-id="credit-card-icon" className="equipement" style={{ backgroundColor: laundryInfo.payments.includes('card') ? 'var(--primary-color)' : '#b2b2b2' }}>
                            <FontAwesomeIcon icon="fa-regular fa-credit-card" size={'lg'} />
                        </div>
                        <p className='fs-sm text-center'>Carte</p>
                    </div>
                    <Tooltip id="credit-card-icon" place="top" content="Carte Bleu" />
                    <div>
                        <div data-tooltip-id="ticket-icon" className="equipement" style={{ backgroundColor: laundryInfo.payments.includes('ticket') ? 'var(--primary-color)' : '#b2b2b2' }}>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" size={'lg'} />
                        </div>
                        <p className='fs-sm text-center'>Billet</p>
                    </div>
                    <Tooltip id="ticket-icon" place="top" content="Billet(s)" />
                    <div>
                        <div data-tooltip-id="coin-icon" className="equipement" style={{ backgroundColor: laundryInfo.payments.includes('coin') ? 'var(--primary-color)' : '#b2b2b2' }}>
                            <FontAwesomeIcon icon="fa-solid fa-coins" size={'lg'} />
                        </div>
                        <p className='fs-sm text-center'>Pièce</p>
                    </div>
                    <Tooltip id="coin-icon" place="top" content="Pièce(s)" />
                    <div>
                        <div data-tooltip-id="token-icon" className="equipement" style={{ backgroundColor: laundryInfo.payments.includes('token') ? 'var(--primary-color)' : '#b2b2b2' }}>
                            <FontAwesomeIcon icon="fa-solid fa-drum-steelpan" size={'lg'} />
                        </div>
                        <p className='fs-sm text-center'>Jeton</p>
                    </div>
                    <Tooltip id="token-icon" place="top" content="Jeton(s)" />
                    <div>
                        <div data-tooltip-id="fidelity-icon" className="equipement" style={{ backgroundColor: laundryInfo.payments.includes('fidelity') ? 'var(--primary-color)' : '#b2b2b2' }}>
                            <FontAwesomeIcon icon="fa-solid fa-medal" size={'lg'} />
                        </div>
                        <p className='fs-sm text-center'>Fidélité</p>
                    </div>
                    <Tooltip id="fidelity-icon" place="top" content="Fidélité" />
                </div>

                { laundryInfo.equipements.length !== 0 &&
                    <h5 className='fs-6 mb-4' style={{ color: 'var(--primary-color)' }}>Liste des équipements</h5>
                }

                <div className='equipement-list mb-3'>
                    {laundryInfo.equipements.includes('parking') &&
                        <div>
                            <div data-tooltip-id="parking-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-square-parking" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Parking</p>
                            <Tooltip id="parking-icon" place="top" content="Parking" />
                        </div>
                    }

                    {laundryInfo.equipements.includes('wifi') &&
                        <div>
                            <div data-tooltip-id="wifi-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-wifi" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Wifi</p>
                            <Tooltip id="wifi-icon" place="top" content="Wifi" />
                        </div>
                    }

                    {laundryInfo.equipements.includes('plug') &&
                        <div>
                            <div data-tooltip-id="plug-circle-bolt-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-plug-circle-bolt" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Prise</p>
                            <Tooltip id="plug-circle-bolt-icon" place="top" content="Prise électrique" />
                        </div>
                    }
                    {laundryInfo.equipements.includes('pressing') &&
                        <div>
                            <div data-tooltip-id="shirt-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-shirt" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Pressing</p>
                            <Tooltip id="shirt-icon" place="top" content="Pressing" />
                        </div>
                    }
                    {laundryInfo.equipements.includes('basket') &&
                        <div>
                            <div data-tooltip-id="basket-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-basket-shopping" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Panier</p>
                            <Tooltip id="basket-icon" place="top" content="Panier" />
                        </div>
                    }

                    {laundryInfo.equipements.includes('restroom') &&
                        <div>
                            <div data-tooltip-id="restroom-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-restroom" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Toilettes</p>
                            <Tooltip id="restroom-icon" place="top" content="Toilettes" />
                        </div>
                    }

                    {laundryInfo.equipements.includes('soap') &&
                        <div>
                            <div data-tooltip-id="soap-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-pump-soap" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Adoucissant</p>
                            <Tooltip id="soap-icon" place="top" content="Adoucissant" />
                        </div>
                    }

                    {laundryInfo.equipements.includes('detergent') &&
                        <div>
                            <div data-tooltip-id="detergent-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-jug-detergent" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Détergent</p>
                            <Tooltip id="detergent-icon" place="top" content="détergent" />
                        </div>
                    }
                    {laundryInfo.equipements.includes('music') &&
                        <div>
                            <div data-tooltip-id="music-icon" className="equipement" style={{ backgroundColor: 'var(--primary-color)' }}>
                                <FontAwesomeIcon icon="fa-solid fa-music" size={'lg'} />
                            </div>
                            <p className='fs-sm text-center'>Musique</p>
                            <Tooltip id="music-icon" place="top" content="Musique" />
                        </div>
                    }
                </div>
            </div>

            {/* Liste des machines */}
            <h5 className='fs-6 mb-4' style={{ color: 'var(--primary-color)' }}>Liste des machines</h5>
            <div className="row" style={{ color: 'black' }}>
                {laundryInfo.machines.map((machine, index) => (
                    <div key={index} className='card col-lg-4 mb-3 pe-4 pt-1'>
                        <div className='card-body p-0'>
                            <div className='row'>
                                <div className='col-4 align-self-center p-0 '>
                                    <img src={
                                        machine.categorie === 'Machines' ?
                                            WashingMachine :
                                            machine.categorie === 'Sèche-linge' ?
                                                DryerLogo :
                                                machine.categorie === 'Produit' ? ProductLogo :
                                                    OthersLogo
                                    }
                                        alt={laundryInfo.nom} style={{ width: '5rem' }} />
                                </div>
                                <div className='fs-xs lh-sm col-8 p-0'>
                                    <div className='row'>
                                        <p className='fs-6 p-0 text-center'><strong>{machine.nom}</strong></p>
                                        {(machine.categorie === 'Machines' || machine.categorie === 'Sèche-linge') &&
                                            <>
                                                <div className='d-flex col-6 p-0'>
                                                    <FontAwesomeIcon icon="fa-solid fa-up-right-and-down-left-from-center" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                    <p className='ms-2 fw-bold'>TAILLE : {machine.details}</p>
                                                </div>
                                                <div className='d-flex col-6 p-0'>
                                                    <FontAwesomeIcon icon="fa-regular fa-clock" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                    <p className='ms-2 fw-bold'>DURÉE : {machine.duree}</p>
                                                </div>
                                            </>
                                        }
                                        <div className='d-flex col-6 p-0'>
                                            <FontAwesomeIcon icon="fa-solid fa-hashtag" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                            <p className='ms-2 fw-bold'>NOMBRE : {machine.nombre}</p>
                                        </div>
                                        <div className='d-flex col-6 p-0'>
                                            <FontAwesomeIcon icon="fa-solid fa-euro-sign" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                            {machine.prixMax === machine.prixMin ?
                                                <p className='ms-2 fw-bold'>PRIX : {machine.prixMin}</p>
                                                : <p className='ms-2 fw-bold'>PRIX : {machine.prixMin}  et  {machine.prixMax}</p>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="d-flex justify-content-center align-items-center mb-3">
                <div className="text-decoration-none text-center">
                    <button type='button' onClick={googleItinerary} style={{ fontSize: '0.8rem', backgroundColor: 'var(--tertiary-color)', color: 'white' }} className="mb-0 btn rounded-pill">
                        ITINERAIRE<span style={{ marginLeft: '5px' }}>{company.distance !== undefined ? '(' + company.distance + ' Km)' : ''}</span>
                    </button>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <MapContainer className="map-Info" center={company.position} zoom={15} scrollWheelZoom={true}>
                        <div className="map-controls">
                            <button style={{ borderRight: '1px solid #cccccc' }}
                                className={`map-control-button ${mapType === 'standard' ? 'active' : ''}`}
                                onClick={() => setMapType('standard')}>
                                Plan
                            </button>
                            <button
                                className={`map-control-button ${mapType === 'satellite' ? 'active' : ''}`}
                                onClick={() => setMapType('satellite')}>
                                Satellite
                            </button>
                        </div>

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url={mapType === 'standard'
                                ? "http://mt2.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=fr"
                                : "http://mt2.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=fr"
                            }
                        />
                        <Marker position={company.position}>
                            <Popup>
                                Ma laverie <br /> (Ouvert)
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
        </div>
    );
}


export default Info;
