import axios from "axios";
import { useEffect, useState } from "react";
import moment from 'moment'


function UserProfil() {

    const [user, setUser] = useState({
        name: '',
        email: '',
        plainPassword: '',
        created_at: '',
        updatedAt: Date.now()
    })
    const [newPassword, setNewPassword] = useState('')
    const [currentUser, setCurrentUser] = useState('')
    const [isValid, setIsValid] = useState(true)
    const [passwordComplexity, setpasswordComplexity] = useState('')
    const [isUnique, setIsUnique] = useState(true)


    useEffect(() => {

        getCurrentUser()

        if (currentUser !== '') {
            getUserInfo()
        }
    }, [currentUser]);

    async function getCurrentUser() {
        await axios.get(process.env.REACT_APP_API_URL + 'auth')
            .then(response => {
                setCurrentUser(response.data.id)
            }).catch(err =>
                err
            )
    }

    async function getUserInfo() {
        await axios.get(process.env.REACT_APP_API_URL + 'users/' + currentUser)
            .then(response => {
                setUser(response.data)
            }).catch(err =>
                err
            )
    }

    function updateUser() {

        if (isValid) {
            axios.put(process.env.REACT_APP_API_URL + 'users/' + currentUser, user)
                .then(response => {
                }).catch(err =>
                    err
                )
        } else {
        }
    }

    function emailIsUnique() {
        axios.post(process.env.REACT_APP_API_URL + 'user/emailIsUnique', { 'email': user.email })
            .then(response => {
                response.data.statut === 200 ? setIsUnique(true) : setIsUnique(false)
            }).catch(err =>
                err
            )
    }

    useEffect(() => {
        if (user.plainPassword !== newPassword) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [user.plainPassword]);

    var specials = new RegExp(/\W/g);
    var lowerCaseLetters = new RegExp(/[a-z]/g);
    var upperCaseLetters = new RegExp(/[A-Z]/g);
    var numbers = new RegExp(/[0-9]/g);

    useEffect(() => {
        if (user.plainPassword !== undefined) {

            var complexity = 0;
            if (lowerCaseLetters.test(user.plainPassword)) {
                complexity += 15;
                document.getElementById("letter").setAttribute("style", "color : green");
            } else {
                document.getElementById("letter").setAttribute("style", "color : '' ")
            }
            if (upperCaseLetters.test(user.plainPassword)) {
                complexity += 15;
                document.getElementById("capital").setAttribute("style", "color : green");
            } else {
                document.getElementById("capital").setAttribute("style", "color : '' ")
            }
            if (specials.test(user.plainPassword)) {
                complexity += 15;
                document.getElementById("special").setAttribute("style", "color : green");
            } else {
                document.getElementById("special").setAttribute("style", "color : '' ")
            }
            if (numbers.test(user.plainPassword)) {
                complexity += 15;
                document.getElementById("number").setAttribute("style", "color : green");
            } else {
                document.getElementById("number").setAttribute("style", "color : '' ")
            }
            if (user.plainPassword.length >= 8) {
                complexity += 40;
                document.getElementById("length").setAttribute("style", "color : green");
            } else {
                document.getElementById("length").setAttribute("style", "color : '' ")
            }
            document.getElementById("mdp_complex").setAttribute("style", "width :" + complexity + "%");
            if (complexity <= 20) {
                document.getElementById("mdp_complex").classList.remove('bg-warning');
                document.getElementById("mdp_complex").classList.remove('bg-success');
                document.getElementById("mdp_complex").classList.add('bg-danger');
                document.getElementById("passwordComplexity").setAttribute("style", "color : red");
                setpasswordComplexity('Très simple')
            } else if (complexity <= 40 && complexity > 20) {
                setpasswordComplexity('Simple')
                document.getElementById("mdp_complex").classList.remove('bg-danger');
                document.getElementById("mdp_complex").classList.remove('bg-success');
                document.getElementById("mdp_complex").classList.add('bg-warning');
                document.getElementById("passwordComplexity").setAttribute("style", "color : yellow");
            } else if (complexity <= 84 && complexity > 40) {
                setpasswordComplexity('Moyen')
                document.getElementById("mdp_complex").classList.remove('bg-danger');
                document.getElementById("mdp_complex").classList.remove('bg-success');
                document.getElementById("mdp_complex").classList.add('bg-warning');
                document.getElementById("passwordComplexity").setAttribute("style", "color : yellow");
            } else if (complexity <= 90 && complexity > 84) {
                setpasswordComplexity('Correct')
                document.getElementById("mdp_complex").classList.remove('bg-danger');
                document.getElementById("mdp_complex").classList.remove('bg-warning');
                document.getElementById("mdp_complex").classList.add('bg-success');
                document.getElementById("passwordComplexity").setAttribute("style", "color : green");

            } else if (complexity > 90) {
                setpasswordComplexity('Très bon !')
                document.getElementById("mdp_complex").classList.remove('bg-danger');
                document.getElementById("mdp_complex").classList.remove('bg-warning');
                document.getElementById("mdp_complex").classList.add('bg-success');
                document.getElementById("passwordComplexity").setAttribute("style", "color : green");
            }
        }

    }, [user.plainPassword]);

    return (
        <>
            <h1 className="text-center">Votre Profil</h1>

            <form>
                <div className="col-2 offset-5 card px-3">
                    <div className="text-center ">
                        <label className="form-label" htmlFor="name" >Nom</label>
                        <input className="form-control" name="name" type={'text'} value={user.name} onChange={(e) => setUser({ ...user, name: e.target.value })} />
                    </div>
                    <div className="text-center">
                        <label className="form-label" htmlFor="email" >Email</label>
                        <input className="form-control" name="email" type={'email'} value={user.email} onBlur={() => emailIsUnique()} onChange={(e) => setUser({ ...user, email: e.target.value })} />
                        {!isUnique &&
                            <p className="text-danger">
                                cet email est deja utilisé
                            </p>
                        }
                    </div>

                    <div className="text-center">
                        <label className="form-label" htmlFor="password" >Changez votre mot de passe</label>
                        <input id="password" className="form-control" name="password" type={'password'} value={user.plainPassword} onChange={(e) => setUser({ ...user, plainPassword: e.target.value })} />
                    </div>
                    <div className="text-center mb-3">
                        <label className="form-label" htmlFor="newPassword" >Confirmez votre mot de passe</label>
                        <input className="form-control" name="newPassword" type={'password'} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                    <div>
                        <div className="progress mb-3">
                            <div id="mdp_complex" className="progress-bar">
                            </div>
                        </div>
                        <span className="mx-auto progress-label">{passwordComplexity}</span>
                    </div>
                    <div id="passwordComplexity" className="text-center">
                        <p></p>
                    </div>
                    <div className="text-center mb-3">Votre mot de passe doit comporter :<br />
                        <span id="length">8 caractères</span>,<br />
                        <span id="capital"> majuscule</span> et
                        <span id="letter"> minuscule</span>,<br />
                        <span id="number"> un nombre</span> et/ou
                        <span id="special"> un caractère spécial.</span>
                    </div>
                    <div className="text-center mb-5">
                        <button type="button" className="btn btn-primary" onClick={() => updateUser()}>Enregistrer</button>
                    </div>
                    <p className="text-center ">profil créé le {moment(user.created_at).format('DD/MM/YYYY')}</p>
                </div>
            </form>
        </>
    );
}

export default UserProfil;