import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultLogo from '../img/laundry-map-logo.png'
import "../css/card.css";
import { Link } from 'react-router-dom';
import logo from "../img/logo.svg";
import axios from 'axios';
import eventBus from './../EventBus';
import UserContext from './../UserContext';

function Card({ company, selectedFilter }) {
    const user = useContext(UserContext);

    const [visible, setVisible] = useState(true)
    const [showPhone, setShowPhone] = useState(false)
    const payments = []

    company.payments.map(payment => {
        payments.push(payment.label)
    })

    const googleItinerary = () => {
        const location = encodeURI(company.number + "," + company.street + "," + company.postal_code + " " + company.city);
        const url = `https://www.google.com/maps/dir/?api=1&destination=${location}`;
        window.open(url, "_blank");
    };

    const ownedByMe = () => {

        if (user !== null && company.created_by != null && user.id === company.created_by.id) {
            return true
        } else {
            return false
        }

    }

    const centerMap = () => {
        eventBus.emit('centerLaundry', company);
    }

    var menuReportingElements = document.querySelectorAll('.laundry-card');

    menuReportingElements.forEach(function (element) {
        element.addEventListener('click', function () {
            element.classList.add('bounce-animation');

            setTimeout(function () {
                element.classList.remove('bounce-animation');
            }, 500);
        });
    });

    return (
        <>
            {visible &&
                <div className='col-xl-6 mb-3' onClick={() => centerMap(company)}>
                    <div className='card laundry-card'>
                        <div className='d-flex logo-div'>
                            <img src={company.logo !== '' ? company.logo.file : DefaultLogo} className="my-2 mx-1 card-img rounded-3" alt="logo" />
                            <div className='w-100'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <h6 className="main-ff mt-2 mb-0 fs-5">{company.name.charAt(0).toUpperCase() + company.name.slice(1)}</h6>
                                    <p className={company.statut === "open" ? 'opened mb-0' : 'closed mb-0'}>{company.statut === "open" ? 'Ouvert' : 'Fermé'}</p>
                                </div>
                                <p style={{ fontSize: '0.8rem', marginBottom: '2px' }}>{company.postal_code}, {company.city.charAt(0).toUpperCase() + company.city.slice(1)}</p>
                                <p className="street" style={{ fontSize: '0.8rem', marginBottom: '2px' }}>{company.number} {company.street}</p>
                            </div>
                        </div>

                        <div className="row m-0 border-top border-2 " >
                            <div className="col-8 py-1">
                                <div className="row">
                                    <div className="col-5 p-0">
                                        <div className="d-flex align-items-center ms-2">
                                            <Link onClick={googleItinerary} className="text-decoration-none text-center text-black">
                                                <p style={{ fontSize: '0.7rem' }} className="mb-0">
                                                    <FontAwesomeIcon icon="fa-solid fa-map-location-dot" color={"var(--primary-color)"} size={'lg'} />
                                                    <span style={{ marginLeft: '5px' }}>{company.distance !== undefined ? '(' + company.distance + ' Km)' : ''}</span>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-7 p-0">
                                        <div className='ms-3 equipement-sm-list'>
                                            <div className={payments.includes('coin') ? "equipement-sm" : 'unselected'}>
                                                <FontAwesomeIcon icon={'fa-solid fa-coins'} size={'xs'} />
                                            </div>
                                            <div className={payments.includes('ticket') ? "equipement-sm" : 'unselected'}>
                                                <FontAwesomeIcon icon={'fa-solid fa-money-bill-wave'} size={'xs'} />
                                            </div>
                                            <div className={payments.includes('card') ? "equipement-sm" : 'unselected'}>
                                                <FontAwesomeIcon icon={'fa-regular fa-credit-card'} size={'xs'} />
                                            </div>

                                            <div className={payments.includes('token') ? "equipement-sm" : 'd-none'}>
                                                <FontAwesomeIcon icon={'fa-solid fa-drum-steelpan'} size={'xs'} />
                                            </div>
                                            <div className={payments.includes('fidelity') ? "equipement-sm" : 'd-none'}>
                                                <FontAwesomeIcon icon={'fa-solid fa-medal'} size={'xs'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-end">
                                <Link to={ownedByMe() ? "/laundries-form" : "/info"} state={{ company: company }} className="text-decoration-none text-black">
                                    {ownedByMe() &&
                                        <span style={{ fontSize: '1rem' }} className="mb-0">
                                            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={"rgba(255,141,47,1)"} />
                                        </span>
                                    }
                                    {!ownedByMe() &&
                                        <p className="detail-button mb-0">
                                            PLUS DE DETAILS
                                        </p>
                                    }
                                </Link>
                            </div>
                        </div >
                    </div>
                </div>
            }
        </>
    );
}

export default Card;
