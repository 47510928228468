import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route, Navigate} from 'react-router-dom';
import Nav from './components/Nav';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import UserContext from './UserContext';
import Info from './components/Info';
import Home from './components/Home';
import Menu from './components/Menu';
import LaundriesUserList from './components/LaundriesUserList';
import LaundriesForm from './components/LaundriesForm';
import UserProfil from './components/UserProfil';
import axios from 'axios';
import React, {useEffect, useState} from 'react'
import bootstrap from 'bootstrap'

import { library } from '@fortawesome/fontawesome-svg-core'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import eventBus from "./EventBus";


export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  else
    delete axios.defaults.headers.common["Authorization"];
}

function App() {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (token) {
      setAuthToken(token);

      axios.get(process.env.REACT_APP_API_URL + 'auth')
          .then(response => {
            eventBus.emit('setAuthUser', response.data);
          }).catch(err =>
          err
      )
    }
    const eventHandler = (data) => {
      setUser(data);
    };

    eventBus.on('setAuthUser', eventHandler);

    return () => {
      eventBus.off('setAuthUser', eventHandler);
    };
  }, []);

  const isUserLoggedIn = () => {
    return user !== null;
  };


  return (
      <UserContext.Provider value={user}>
        <div className="App">
          <Nav/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/menu' element={<Menu/>}/>
            <Route path='/login-form' element={<LoginForm/>}/>
            <Route path='/register-form' element={<RegisterForm/>}/>
            <Route path='/info' element={<Info/>}/>
            <Route
                path="/laundries-user-list"
                element={isUserLoggedIn() ? <LaundriesUserList /> : <Navigate to="/" />}
            />
            <Route
                path="/laundries-form"
                element={isUserLoggedIn() ? <LaundriesForm /> : <Navigate to="/" />}
            />
            <Route
                path="/user-profil"
                element={isUserLoggedIn() ? <UserProfil /> : <Navigate to="/" />}
            />
          </Routes>
        </div>
      </UserContext.Provider>
  );
}

export default App;
library.add(fab, fas, far)