import React, { useState, useEffect } from "react";
import "../css/loginForm.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import logo from '../img/laundry-map-logo.png';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function RegisterForm() {
    const [user, setUser] = useState('')
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [nameErrors, setNameErrors] = useState([])
    const [emailErrors, setEmailErrors] = useState([])
    const [passwordErrors, setPasswordErrors] = useState([])
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState([])

    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)

    var specials = new RegExp(/\W/g);
    var lowerCaseLetters = new RegExp(/[a-z]/g);
    var upperCaseLetters = new RegExp(/[A-Z]/g);
    var numbers = new RegExp(/[0-9]/g);

    useEffect(() => {

        let newErrors = []

        if (name !== null) {
            if (name === '') {
                newErrors.push('Ce champs est requis.')
            }
            if (name.length > 50) {
                newErrors.push('Le nom saisie est trop long.')
            }
        }
        setNameErrors(newErrors)

    }, [name]);

    useEffect(() => {

        let newErrors = []

        if (email !== null) {
            if (email === '') {
                newErrors.push('Ce champs est requis.')
            }
            if (email !== '') {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                    newErrors.push('L\'email saisie doit être valide.')
                }
            }
        }
        setEmailErrors(newErrors)

    }, [email]);

    useEffect(() => {

        let newErrors = []

        if (password !== null) {

            if (password === '') {
                newErrors.push('Ce champs est requis.')
            }
            if (password !== '') {
                if (!lowerCaseLetters.test(password)) {
                    newErrors.push('Votre mot de passe doit contenir une lettre minuscule.')
                }
                if (!upperCaseLetters.test(password)) {
                    newErrors.push('Votre mot de passe doit contenir une lettre majuscule.')
                }
                if (!specials.test(password)) {
                    newErrors.push('Votre mot de passe doit contenir un caractère spécial.')
                }
                if (!numbers.test(password)) {
                    newErrors.push('Votre mot de passe doit contenir un chiffre.')
                }
                if (password.length < 8) {
                    newErrors.push('Votre mot de passe doit faire à minima 8 caractères de long.')
                }
            }
        }
        setPasswordErrors(newErrors)

    }, [password]);

    useEffect(() => {

        let newErrors = []

        if (confirmPassword !== null) {
            if (confirmPassword === '') {
                newErrors.push('Ce champs est requis.')
            }
            if (password !== confirmPassword) {
                newErrors.push('Le mot de passe doit être identique.')
            }
        }
        setConfirmPasswordErrors(newErrors)

    }, [confirmPassword]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (nameErrors.length === 0 && emailErrors.length === 0 && passwordErrors.length === 0 && confirmPasswordErrors.length === 0) {
            setUser({ name: name, email: email, password: password, validated: false, created_at: new Date() })

            axios.post(process.env.REACT_APP_API_URL + 'user/register', user)
                .then(response => {
                    MySwal.fire({
                        title: <p>Inscription réussi</p>,
                        didOpen: () => {
                            MySwal.getValidationMessage()
                        },
                    })
                    navigate('/login-form')
                }).catch(err =>
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                      })

                );
        } else {
            MySwal.fire({
                title: <p>Certains champs n'ont pas été remplis correctement</p>,
                didOpen: () => {
                    MySwal.getValidationMessage()
                },
            })
        }
    }

    return (

        <div>
            <div style={{ paddingTop: '3.5rem' }}></div>
            <div className="position-absolute w-100" style={{ zIndex: '0' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave2_Primary waveColorsSecondary"></path>
                    <path className="wave2_Secondary waveColorsMain"></path>
                </svg>
            </div>
            <div className="d-flex flex-column align-items-center">
                <h1 className="mb-5 z-index text-white main-ff">CRÉER UN COMPTE</h1>
                <img src={logo} className="modal-logo rounded-4 z-index" alt="logo" />
            </div>

            <form className="login-form mt-3" onSubmit={handleSubmit}>
                <div>
                    <input id="name" type="text" className="login-input" placeholder="Veuillez saisir votre nom"
                        value={name} onChange={(event) => setName(event.target.value)} />

                    <div className="lh-sm">
                        {name !== null &&
                            nameErrors.map(error => {
                                return <p className="error-text ps-2 mb-1">{error}</p>
                            })}
                    </div>
                </div>
                <div>
                    <input id="email" type="email" className="login-input" placeholder="Veuillez saisir votre email"
                        value={email} onChange={(event) => setEmail(event.target.value)} />

                    <div className="lh-sm">
                        {email !== null &&
                            emailErrors.map(error => {
                                return <p className="error-text ps-2 mb-1">{error}</p>
                            })}
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <input id="password" type="password" className="login-input mb-3 align-self-center" placeholder="Veuillez saisir votre mot de passe"
                        value={password} onChange={(event) => setPassword(event.target.value)} />

                    <div className="lh-sm ms-0" style={{maxWidth: '17rem'}}>
                        {password !== null &&
                            passwordErrors.map(error => {
                                return <p className="error-text ps-2 mb-1">{error}</p>
                            })}
                    </div>
                </div>
                <div>
                    <input id="confirmPassword" type="password" className="login-input mb-3" placeholder="Veuillez confirmer votre mot de passe"
                        value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />

                    <div className="lh-sm">
                        {confirmPassword !== null &&
                            confirmPasswordErrors.map(error => {
                                return <p className="error-text ps-2 mb-1">{error}</p>
                            })}
                    </div>
                </div>
                <div>
                    <button type="submit" className="login-submit main-ff">s'inscrire</button>
                </div>
            </form>
            <div>
                <p className="position-absolute start-50 translate-middle-x" style={{ top: '92%', zIndex: '1', color: 'var(--primary-color)' }} onClick={() => navigate('/login-form')}>
                    Retour
                </p>
            </div>
            <div className="reverse position-absolute bottom-0 w-100" style={{ zIndex: '0' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave1_Primary waveColorsSecondary"></path>
                    <path className="wave1_Secondary waveColorsMain"></path>
                </svg>
            </div>
        </div>

    );
}

export default RegisterForm;