import React, { useState, useEffect } from "react";
import { Link, redirect } from 'react-router-dom';
import axios from 'axios';
import UserListCard from './UserListCard';
import "../css/LaundriesUserList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'


function LaundriesUserList() {
    const [laundriesList, setLaundriesList] = useState([])
    const [currentUser, setCurrentUser] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        getCurrentUser()
    }, [])

    useEffect(() => {
        getLaundriesList()
    }, [currentUser])

    async function getCurrentUser() {
        await axios.get(process.env.REACT_APP_API_URL + 'auth')
            .then(response => {
                setCurrentUser(response.data)

            }).catch(err => {
                if (err.response.status === 401) {
                    setError(true)
                }
            }

            );
    }

    async function getLaundriesList() {
        await axios.get(process.env.REACT_APP_API_URL + 'laundries/user/' + currentUser.id)
            .then(response => {
                setLaundriesList(response.data)
            }).catch(err =>
                err
            )
    }

    if (error === true) {
        return (
            <h1> You are not authentificated pls connect</h1>
        )
    } else {
        if (laundriesList.length !== 0) {
            return (
                <>
                    <div className="laudries-list-header pt-5 text-white">
                        <h2 className="pt-4 pb-2">Vos Laveries</h2>

                    </div>

                    <div className="row px-3">
                        {laundriesList.map((company, index) => {
                            return <UserListCard key={index} company={company} />
                        })
                        }
                        <Link to="/laundries-form" className="icon-add col-4 row">
                            <button><FontAwesomeIcon icon={faPlus} alt="plus" /></button>
                        </Link>
                    </div>


                </>
            );
        } else {
            return (
                <>
                    <div className="laudries-list-header">
                        <h2>Vos Laveries</h2>

                    </div>
                    <div className="laudries-list-card row">
                        <div className="userListCard-react col-4">
                            <img className="userListCard-react-logo skeleton" />
                            <div className="head-card-skeleton">
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                            </div>
                        </div>
                        <div className="userListCard-react col-4">
                            <img className="userListCard-react-logo skeleton" />
                            <div className="head-card-skeleton">
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                            </div>
                        </div>
                        <div className="userListCard-react col-4">
                            <img className="userListCard-react-logo skeleton" />
                            <div className="head-card-skeleton">
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                            </div>
                        </div>
                        <div className="userListCard-react col-4">
                            <img className="userListCard-react-logo skeleton" />
                            <div className="head-card-skeleton">
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                            </div>
                        </div>
                        <div className="userListCard-react col-4">
                            <img className="userListCard-react-logo skeleton" />
                            <div className="head-card-skeleton">
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                                <div className="skeleton skeleton-text"></div>
                            </div>
                        </div>

                        <Link to="../add" className="icon-add col-4">
                            <button><FontAwesomeIcon icon={faPlus} alt="plus" /></button>
                        </Link>
                    </div>
                </>
            )
        }
    }
}

export default LaundriesUserList;