import React, { useState } from "react";
import "../css/loginForm.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import logo from '../img/laundry-map-logo.png';
import eventBus from './../EventBus';

function LoginForm() {

    const setAuthToken = token => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        else
            delete axios.defaults.headers.common["Authorization"];
    }
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();

        axios.post(process.env.REACT_APP_API_URL + 'login', { email, password })
            .then(response => {
                const jwt = response.data.token;
                if (jwt) {
                    localStorage.setItem('jwt', jwt);
                    setAuthToken(jwt);
                    axios.get(process.env.REACT_APP_API_URL + 'auth')
                        .then(response => {
                            eventBus.emit('setAuthUser', response.data);
                        }).catch(err =>
                            err
                        )

                    navigate("/");
                }
            })
    }

    return (
        <div>
        <div style={{paddingTop: '3.5rem'}}></div>
            <div className="position-absolute w-100" style={{ zIndex: '0' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave2_Primary waveColorsSecondary"></path>
                    <path className="wave2_Secondary waveColorsMain"></path>
                </svg>
            </div>
            <div className="d-flex flex-column align-items-center" style={{ zIndex: '1' }}>
                <h1 className="mb-5 z-index text-white main-ff">Connexion</h1>
                <img src={logo} className="modal-logo rounded-4 z-index" alt="logo" />
            </div>

            <form className="login-form z-index" onSubmit={handleSubmit} >
                <div>
                    <input id="email" type="email" className="login-input" placeholder="Veuillez saisir votre email"
                        value={email} onChange={(event) => setEmail(event.target.value)} />
                </div>
                <div>
                    <input id="password" type="password" className="login-input mb-3" placeholder="Veuillez saisir votre mot de passe"
                        value={password} onChange={(event) => setPassword(event.target.value)} />
                </div>
                <div>
                    <button type="submit" className="login-submit">Connexion</button>
                </div>
            </form>
            <div>
                <p className='position-absolute start-50 translate-middle-x main-ff' style={{ top: '92%', zIndex: '1', color: 'var(--primary-color)' }} onClick={() => navigate('/register-form')}>
                    Créer un compte
                </p>
            </div>
            <div className="reverse position-absolute bottom-0 w-100" style={{ zIndex: '0' }}>
                <svg viewBox="0 0 500 300">
                    <path className="wave1_Primary waveColorsSecondary"></path>
                    <path className="wave1_Secondary waveColorsMain"></path>
                </svg>
            </div>
        </div>
    );
}

export default LoginForm;