import React, { useEffect, useRef, useState, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import RedMarker from "../img/map-marker-icon.png";
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import L from 'leaflet'
import "../css/map.css";
import eventBus from './../EventBus';
import UserContext from './../UserContext';

function LocationMarker() {
    const LeafIcon = L.Icon.extend({
        options: {}
    });

    const redIcon = new LeafIcon({
        iconUrl: RedMarker,
        shadowUrl: "https://unpkg.com/leaflet@1.9.3/dist/images/marker-shadow.png",

        iconSize: [36, 42], // size of the icon
        shadowSize: [36, 36], // size of the shadow
        iconAnchor: [20, 34], // point of the icon which will correspond to marker's location
        shadowAnchor: [11, 29],  // the same for the shadow
        popupAnchor: [-2, -30] // point from which the popup should open relative to the iconAnchor
    })

    const [positionUser, setPosition] = useState(null)
    const map = useMapEvents({
        locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        },
    })


    if (map._zoom === 6) {
        map.locate()
        map._zoom = 13;
    }

    return positionUser === null ? null : (
        <Marker position={positionUser} icon={redIcon}>
            <Popup>Vous êtes ici</Popup>
        </Marker>
    )
}
function CarouselContent({ images }) {
    return (
        <Carousel indicators={false}>
            {images.map((imageUrl, index) => (
                <Carousel.Item key={index}>
                    <img className="d-block w-100 carousel-image-miniature" src={imageUrl} alt={`Image ${index}`} />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

function Map({ companies, selectedFilter }) {
    const [laundryPosition, setLaundryPosition] = useState(null);
    const mapRef = useRef(null);
    const [mapType, setMapType] = useState('standard'); // 'standard' ou 'satellite'
    const [filteredMarkers, setFilteredMarkers] = useState([]);
    const user = useContext(UserContext);
    const [isInvalidAddress, setIsInvalidAddress] = useState(false)

    useEffect(() => {
        var mapContainer = document.getElementById("MapContainer")
        if (isInvalidAddress) {
            mapContainer.classList.add('blur')
        } else {
            mapContainer.classList.remove('blur')
        }

    }, [isInvalidAddress]);

    useEffect(() => {
        const eventHandler = (data) => {

            setLaundryPosition([data.latitude, data.longitude]);

            if (data.latitude !== 0 && data.longitude !== 0) {
                setIsInvalidAddress(false)
                // Centrer la carte sur les nouvelles coordonnées de la laundry en utilisant la méthode flyTo().
                if (mapRef.current) {
                    mapRef.current.flyTo([data.latitude, data.longitude], 13); // Utilisez le niveau de zoom souhaité, ici 13.
                }
            } else {
                setIsInvalidAddress(true)
            }
        };

        eventBus.on('centerLaundry', eventHandler);

        return () => {
            eventBus.off('centerLaundry', eventHandler);
        };
    }, []);

    useEffect(() => {

        if (companies && companies.length > 0) {
            let filteredCompanies = companies.filter((company) => {
                let respectedFilters = 0;
                selectedFilter.forEach((filter) => {
                    if (filter === 'open' && company.statut === 'open') {
                        respectedFilters++
                    }

                    const paymentWithFilter = company.payments.find(
                        (payment) => payment.label === filter
                    );
                    if (paymentWithFilter) {
                        respectedFilters++;
                    }
                    const parkingtWithFilter = company.equipements.find(equipement => equipement.label === filter);

                    if (parkingtWithFilter) {
                        respectedFilters++
                    }
                });
                return respectedFilters === selectedFilter.length;
            });

            const companyPositions = filteredCompanies.map(
                (company) => company.position || [company.latitude, company.longitude]
            );

            if (mapRef.current && companyPositions.length != 0) {
                const bounds = L.latLngBounds(companyPositions);
                mapRef.current.fitBounds(bounds, { padding: [50, 50] });
            }

            const updatedMarkers = filteredCompanies.map((company, index) => (
                (company.latitude !== 0 && company.longitude !== 0) &&
                <Marker key={index} position={company.position}>
                    <Popup style={{ width: '200px' }}>
                        <Link to="/info" state={{ company: company }} className='leaflet-popup-link'>Détails</Link>
                        <p className='leaflet-popup-title'>{company.name}</p>
                        <div style={{ lineHeight: '2px', marginTop: '5px', marginLeft: '5px' }}>
                            <p style={{ fontSize: '0.8rem' }}>{company.postal_code}, {company.city.charAt(0).toUpperCase() + company.city.slice(1)}</p>
                            <p style={{ fontSize: '0.8rem', marginBottom: '10px' }}>{company.number} {company.street}</p>
                        </div>
                        {company.files && <CarouselContent id='miniatureCarousel' images={company.files.map(file => file.content.file)} />}
                    </Popup>
                </Marker>
            ));

            setFilteredMarkers(updatedMarkers);
        }
    }, [companies, selectedFilter]);

    if (companies !== undefined) {
        var center = [49, 2.3];
        var zoom = 6;

        companies.map((company, index) => {
            if (company.position === undefined) {
                company.position = [company.latitude, company.longitude]
            }
        })

        return (
            <div className='map'>
                <MapContainer id='MapContainer' ref={mapRef} className="map-Home rounded-3" center={laundryPosition || center} zoom={laundryPosition ? 13 : zoom} scrollWheelZoom={false}>
                    <div className="map-controls">
                        <button style={{ borderRight: '1px solid #cccccc' }}
                            className={`map-control-button ${mapType === 'standard' ? 'active' : ''}`}
                            onClick={() => setMapType('standard')}>
                            Plan
                        </button>
                        <button
                            className={`map-control-button ${mapType === 'satellite' ? 'active' : ''}`}
                            onClick={() => setMapType('satellite')}>
                            Satellite
                        </button>
                    </div>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url={mapType === 'standard'
                            ? "http://mt2.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=fr"
                            : "http://mt2.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=fr"
                        }
                    />
                    {<LocationMarker />}

                    {filteredMarkers}

                </MapContainer>
                {isInvalidAddress &&

                    <div className='invalid-address-card card '>
                        <h3>Affichage Impossible</h3>
                        <p>L'adresse de la laverie est invalide</p>
                    </div>
                }
            </div>
        );
    } else {
        var centerNoCharge = [49, 2.3];
        var zoomNoCharge = 5;

        return (
            <MapContainer className="map-Home rounded-3" center={centerNoCharge} zoom={zoomNoCharge} scrollWheelZoom={false}>
                <div className="map-controls">
                    <button
                        className={`map-control-button ${mapType === 'standard' ? 'active' : ''}`}
                        onClick={() => setMapType('standard')}
                    >
                        Vue Standard
                    </button>
                    <button
                        className={`map-control-button ${mapType === 'satellite' ? 'active' : ''}`}
                        onClick={() => setMapType('satellite')}
                    >
                        Vue Satellite
                    </button>
                </div>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url={mapType === 'standard'
                        ? "http://mt2.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=fr"
                        : "http://mt2.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=fr"
                    }
                />
                if
                {<LocationMarker />}

            </MapContainer>);
    }
}

export default Map;
