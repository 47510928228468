import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import "../css/laundriesForm.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from "axios";
import { SearchBox } from '@mapbox/search-js-react';
import UserContext from "../UserContext";
import ProductLogo from '../img/product.png'
import DryerLogo from '../img/dryier.png'
import WashingMachine from '../img/washingMachine.png'
import OthersLogo from '../img/others.png'


const WeekdayCheckbox = ({ day, checked, onChange }) => {
    return (
        <label>
            <span>{day}</span>
            <input
                className="form-check-input"
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
        </label>
    );
};

function LaundriesForm() {

    //Déclaration des datas
    const user = useContext(UserContext);
    const [errors, setErrors] = useState([]);
    const [isValidate, setIsValidate] = useState(true);
    const [isOpenAllDay, setIsOpenAllDay] = useState(false)

    const [equipements, setEquipements] = useState([]);
    const [payments, setPayments] = useState([]);
    const [preview, setPreview] = useState()
    const navigate = useNavigate()
    const location = useLocation()//.state.company
    const logoRef = useRef(null);
    const [laundry, setLaundry] = useState({
        name: null,
        city: '',
        postal_code: '',
        street: '',
        number: '',
        phone_number: null,
        email: null,
        description: null,
        links: [],
        equipements: [],
        payments: [],
        hours: [],
        logo: [],
        logoId: "",
        files: [],
        latitude: 49.127098,
        longitude: 2.24368,
        createdBy: [],
        user: '',
        machines: [],
        createdAt: new Date()
    });

    const [scheduleData, setScheduleData] = useState([]);
    const [link, setLink] = useState("");
    const [type, setType] = useState("");
    const [machinesName, setMachinesName] = useState("")
    const [machinesCateg, setMachinesCateg] = useState("")
    const [machinesDetail, setMachinesDetail] = useState("")
    const [machinesDuration, setMachinesDuration] = useState("")
    const [machinesMinPrice, setMachinesMinPrice] = useState("")
    const [machinesMaxPrice, setMachinesMaxPrice] = useState("")
    const [machinesCount, setMachinesCount] = useState("")
    const [currentMachinesName, setCurrentMachinesName] = useState("")
    const [currentMachinesCateg, setCurrentMachinesCateg] = useState("")
    const [currentMachinesDetail, setCurrentMachinesDetail] = useState("")
    const [currentMachinesDuration, setCurrentMachinesDuration] = useState("")
    const [currentMachinesMinPrice, setCurrentMachinesMinPrice] = useState("")
    const [currentMachinesMaxPrice, setCurrentMachinesMaxPrice] = useState("")
    const [currentMachinesCount, setCurrentMachinesCount] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const [fullAddress, setFullAddress] = useState('')

    const utilsIcons = {
        'fidelity': { icon: 'fa-solid fa-medal', label: 'Fidélité' },
        'card': { icon: 'fa-regular fa-credit-card', label: 'Carte' },
        'coin': { icon: 'fa-solid fa-coins', label: 'Pièce' },
        'ticket': { icon: 'fa-solid fa-money-bill-wave', label: 'Billet' },
        'token': { icon: 'fa-solid fa-drum-steelpan', label: 'Jeton' },
        'parking': { icon: 'fa-solid fa-square-parking', label: 'Parkink' },
        'wifi': { icon: 'fa-solid fa-wifi', label: 'Wifi' },
        'pressing': { icon: 'fa-solid fa-shirt', label: 'Pressing' },
        'plug': { icon: 'fa-solid fa-plug-circle-bolt', label: 'Prise' },
        'basket': { icon: 'fa-solid fa-basket-shopping', label: 'Panier' },
        'restroom': { icon: 'fa-solid fa-restroom', label: 'Toilettes' },
        'soap': { icon: 'fa-solid fa-pump-soap', label: 'Adoucissant' },
        'detergent': { icon: 'fa-solid fa-jug-detergent', label: 'Détergent' },
        'music': { icon: 'fa-solid fa-music', label: 'Musique' },
    }

    const center = {
        lat: laundry.latitude,
        lng: laundry.longitude,
    }

    // initialisation du states des horaires
    // initialisation des states equipements et payments
    // réecriture du CSS de la searchbox sur l'adresse
    useEffect(() => {
        setScheduleData([
            {
                id: null,
                day: 'Lundi',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            },
            {
                id: null,
                day: 'Mardi',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            },
            {
                id: null,
                day: 'Mercredi',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            },
            {
                id: null,
                day: 'Jeudi',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            },
            {
                id: null,
                day: 'Vendredi',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            },
            {
                id: null,
                day: 'Samedi',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            },
            {
                id: null,
                day: 'Dimanche',
                open: true,
                startHour: '07:00',
                endHour: '22:30',
            }
        ])

        if (location.state !== null) {
            axios.get(process.env.REACT_APP_API_URL + 'laundry/' + location.state.company.id)
                .then(response => {
                    let data = response.data
                    if (data.logo == []) {
                        setLaundry({ ...data, logo: [] });
                    } else {
                        setLaundry({ ...data });
                    }
                })

        }
        getEquipementsList()
        getPaymentsList()
        setIsLoaded(true)

        window.scroll(0, 0);  

    }, []);

    useEffect(() => {
        if (laundry.number !== '' && laundry.street !== '' && laundry.postal_code !== '' && laundry.city !== '') {
            setFullAddress(laundry.number + ' ' + laundry.street + ' ' + laundry.postal_code + ' ' + laundry.city)
        }
    }, [laundry.number, laundry.street, laundry.postal_code, laundry.city]);

    useEffect(() => {
        // ce code marche grâce a la stucture HTML actuelle
        // si modification de l'architecture de la section information, cette partie ne marchera plus
        let searchInput = document.getElementById('section-information')
        if (fullAddress === '') {
            searchInput = searchInput.children[1].firstElementChild
        } else {
            searchInput = searchInput.children[2].firstElementChild
        }
        searchInput.className = errors.address ? "bg-transparent border-0 text-white border-bottom error-border" : "mb-3 bg-transparent border-0 text-white border-bottom"
        let searchIcon = searchInput.firstElementChild
        searchIcon.classList.add('d-none')
        let searchAction = searchInput.lastElementChild
        searchAction.classList.add('d-none')
        searchInput = searchInput.children[2]
        searchInput.classList.add('p-0', 'searchBox-placeholder', 'text-white')
    }, [errors.address]);

    // watch sur les horaires afin de permettre leur transposition en bitfield
    useEffect(() => {
        const joursSemaine = {
            'Lundi': 1,
            'Mardi': 2,
            'Mercredi': 4,
            'Jeudi': 8,
            'Vendredi': 16,
            'Samedi': 32,
            'Dimanche': 64,
        };

        scheduleData.forEach(scheduleDay => {

            if (scheduleDay.open && scheduleDay.startHour !== '' && scheduleDay.endHour !== '') {
                var morningBitfieldData = { day: joursSemaine[scheduleDay.day], start_hour: scheduleDay.startHour, end_hour: scheduleDay.endHour };

                const existingMorningEntryIndex = laundry.hours.findIndex(entry => entry.day === morningBitfieldData.day);

                if (existingMorningEntryIndex === -1) {
                    laundry.hours.push(morningBitfieldData);
                } else {
                    laundry.hours[existingMorningEntryIndex] = morningBitfieldData;
                }

                const lastEntryIndex = laundry.hours.reduceRight((acc, entry, index) => {
                    if (entry.day === morningBitfieldData.day) {
                        acc = index;
                    }
                    return acc;
                }, -1);

                if (lastEntryIndex !== -1) {
                    laundry.hours.splice(lastEntryIndex + 1, 1);
                }

            } else {
                laundry.hours = laundry.hours.filter(entry => entry.day !== joursSemaine[scheduleDay.day]);
            }
        });

    }, [scheduleData]);

    // watch sur le logo afin de l'afficher en fonction de son format
    useEffect(() => {
        if (!laundry.logo[0]) {
            setPreview(undefined)
        } else {
            setLaundry({ ...laundry, logoId: null })
            const objectUrl = URL.createObjectURL(laundry.logo[0])
            setPreview(objectUrl)

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }

    }, [laundry.logo])

    // controle de saisi sur les champs du formulaire
    useEffect(() => {
        if (typeof laundry.name === 'undefined' || laundry.name === '') {
            setErrors({ ...errors, name: 'Ce champ doit être rempli' })
        } else if ((laundry.name === null ? 0 : laundry.name.length) > 50) {
            setErrors({ ...errors, name: 'Ce champs est limité à 50 caractères' })
            if (laundry.name.length > 51) {
                setLaundry({ ...laundry, name: laundry.name.substring(0, laundry.name.length - 1) })
            }
        } else if (typeof errors['name'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['name']
            setErrors({ ...errorCopie })
        };
    }, [laundry.name]);

    useEffect(() => {
        if (typeof laundry.phone_number === 'undefined' || laundry.phone_number === '') {
            setErrors({ ...errors, phone_number: 'Ce champ doit être rempli' })
        } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/i.test(laundry.phone_number) && laundry.phone_number !== null) {
            setErrors({ ...errors, phone_number: 'Le format du numéro de téléphone est invalide' })
        } else if (typeof errors['phone_number'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['phone_number']
            setErrors({ ...errorCopie })
        };
    }, [laundry.phone_number]);

    useEffect(() => {
        if (typeof laundry.email === 'undefined' || laundry.email === '') {
            setErrors({ ...errors, email: 'Ce champ doit être rempli' })
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(laundry.email) && laundry.email !== null) {
            setErrors({ ...errors, email: 'Le format de l\'adresse email est invalide' })
        } else if (typeof errors['email'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['email']
            setErrors({ ...errorCopie })
        };
    }, [laundry.email]);

    useEffect(() => {
        if (typeof laundry.description === 'undefined' || laundry.description === '') {
            setErrors({ ...errors, description: 'Ce champ doit être rempli' })
        } else if ((laundry.description === null ? 0 : laundry.description.length) > 255) {
            setErrors({ ...errors, description: 'Ce champs est limité à 255 caractères' })
            if (laundry.description.length > 256) {
                setLaundry({ ...laundry, description: laundry.description.substring(0, laundry.description.length - 1) })
            }
        } else if (typeof errors['description'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['description']
            setErrors({ ...errorCopie })
        };
    }, [laundry.description]);

    useEffect(() => {
        if ((typeof laundry.city === 'undefined' || laundry.city === '') && (typeof laundry.street === 'undefined' || laundry.street === '') && (typeof laundry.number === 'undefined' || laundry.number === '') && (typeof laundry.postal_code === 'undefined' || laundry.postal_code === '') && laundry.latitude !== 49.127098) {
            setErrors({ ...errors, address: 'Ce champ doit être rempli' })
        }
    }, [laundry.latitude]);

    useEffect(() => {
        if (laundry.files.length > 5) {
            setErrors({ ...errors, files: 'Vous ne pouvez pas ajouter plus de 5 photos' })
        } else if (typeof errors['files'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['files']
            setErrors({ ...errorCopie })
        }
    }, [laundry]);

    useEffect(() => {
        let hourError = false
        let dayError = ''
        scheduleData.map(hour => {
            if (hour.startHour > hour.endHour) {
                hourError = true
                dayError = hour.day
            }
        })
        if (hourError === true) {
            setErrors({ ...errors, hours: 'L\'horaire renseigné pour le ' + dayError + ' est incorrecte' })
        } else if (typeof errors['hours'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['hours']
            setErrors({ ...errorCopie })
        }
    }, [scheduleData]);

    useEffect(() => {
        if (/[a-z]|[A-Z]/i.test(machinesMinPrice)) {
            setErrors({ ...errors, machinesMinPrice: 'Veuillez rentré un chiffre ou un nombre' })
        } else if (machinesMinPrice > machinesMaxPrice) {
            setErrors({ ...errors, machinesMinPrice: 'Le prix minimum ne peut pas être inférieur au prix maximum' })
        } else if (typeof errors['machinesMinPrice'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['machinesMinPrice']
            setErrors({ ...errorCopie })
        };
    }, [machinesMinPrice, machinesMaxPrice]);

    useEffect(() => {
        if (/[a-z]|[A-Z]/i.test(machinesMaxPrice)) {
            setErrors({ ...errors, machinesMaxPrice: 'Veuillez rentré un chiffre ou un nombre' })
        } else if (machinesMinPrice > machinesMaxPrice) {
            setErrors({ ...errors, machinesMaxPrice: 'Le prix minimum ne peut pas être inférieur au prix maximum' })
        } else if (typeof errors['machinesMaxPrice'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['machinesMaxPrice']
            setErrors({ ...errorCopie })
        };
    }, [machinesMinPrice, machinesMaxPrice]);

    useEffect(() => {
        if (/[a-z]|[A-Z]/i.test(machinesCount)) {
            setErrors({ ...errors, machinesCount: 'Veuillez rentré un chiffre ou un nombre' })
        } else if (typeof errors['machinesCount'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['machinesCount']
            setErrors({ ...errorCopie })
        };
    }, [machinesCount]);

    //appels axios
    async function getEquipementsList() {
        await axios.get(process.env.REACT_APP_API_URL + 'equipements')
            .then(response => {
                let newEquipements = []
                response.data['hydra:member'].forEach(equipement => {
                    newEquipements.push({ label: equipement.name, value: equipement.id, isActive: false, icon: utilsIcons[equipement.name].icon })
                })
                setEquipements(newEquipements)
            }).catch(err =>
                err
            )
    }

    async function getPaymentsList() {
        await axios.get(process.env.REACT_APP_API_URL + 'payments')
            .then(response => {
                let newPayments = []
                response.data['hydra:member'].forEach(payment => {
                    newPayments.push({ label: payment.name, value: payment.id, isActive: false, icon: utilsIcons[payment.name].icon })
                })
                setPayments(newPayments)
            }).catch(err =>
                err
            )
    }

    function setActivePayment(id) {
        let updatedPayments = [...payments]
        updatedPayments.forEach(payment => {
            if (payment.value === id) {
                if (payment.isActive === false) {
                    payment.isActive = true
                } else {
                    payment.isActive = false
                }
            }
        });
        setPayments(updatedPayments)
    }

    function setActiveEquipement(id) {
        let updatedEquipements = [...equipements]
        updatedEquipements.forEach(equipement => {
            if (equipement.value === id) {
                if (equipement.isActive === false) {
                    equipement.isActive = true
                } else {
                    equipement.isActive = false
                }
            }
        });
        setEquipements(updatedEquipements)
    }

    const addNewLink = () => {

        if (link === '' && type !== '') {
            setErrors({ ...errors, links: 'Le lien doit être renseigné' })
        } else if (link !== '' && type === '') {
            setErrors({ ...errors, links: 'Le type de réseau social doit être renseigné' })
        } else {
            let errorCopie = { ...errors }
            delete errorCopie['links']
            setErrors({ ...errorCopie })

            // Ajouter le nouvel élément à la liste
            setLaundry({ ...laundry }, laundry.links.push({ id: null, type: type, link: link }))
            // Réinitialiser les champs de saisie
            setLink("");
            setType("");
        }

    };

    //Gestion des fichiers
    const filesChange = (event) => {
        const files = event.target.files;
        const file = laundry.files
        for (let i = 0; i < files.length; i++) {
            file.push(files[i]);
        }

        setLaundry({ ...laundry, files: file });
    };

    const logoChange = (event) => {
        const logo = event.target.files
        setLaundry({ ...laundry, logo: logo });
    };

    const removeLogo = () => {
        setPreview(undefined)
        setLaundry({ ...laundry, logo: [] });
        logoRef.current = null;
    };

    // Ajout d'un laverie
    function saveLaundry() {
        let addedPayments = []
        let addedEquipements = []

        payments.forEach(payment => {
            if (payment.isActive === true) {
                addedPayments.push(payment.value)
            }
        });

        equipements.forEach(equipement => {
            if (equipement.isActive === true) {
                addedEquipements.push(equipement.value)
            }
        });

        if (typeof errors[0] !== 'undefined') {
            setIsValidate(false)
        } else {
            setIsValidate(true)

            laundry.user = user.id
            laundry.equipements = addedEquipements
            laundry.payments = addedPayments

            const formData = new FormData();
            for (let i = 0; i < laundry.files.length; i++) {
                if (laundry.files[i].id !== undefined) {
                    formData.append('files[]', JSON.stringify(laundry.files[i]['id']));
                } else {
                    formData.append('files[]', laundry.files[i]);
                }
            }
            setLaundry({ ...laundry, files: [], logo: [] })
            formData.append('logo', laundry.logo[0])
            formData.append('laundry', JSON.stringify(laundry))


            if (location.state != undefined) {
                axios.post(process.env.REACT_APP_API_URL + 'updateLaundry/' + location.state.company.id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        // navigate('/laundries-user-list')
                    }).catch(err =>
                        err
                    );

            } else {
                axios.post(process.env.REACT_APP_API_URL + 'laundries/add', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        // navigate('/laundries-user-list')
                    }).catch(err =>
                        err
                    );
            }
        }
    };

    // ajout d'une machine
    function addNewMachines() {

        if (machinesName === '' || machinesMinPrice === '' || machinesMaxPrice === '' || machinesCateg === '') {
            setErrors({ ...errors, machines: 'Vous devez au moins remplir les champs: Nom, Catégotie, Prix minimum et prix maximum' })
        } else if (typeof errors['machinesMinPrice'] !== 'undefined' || typeof errors['machinesMaxPrice'] !== 'undefined' || typeof errors['machinesName'] !== 'undefined' || typeof errors['machinesCateg'] !== 'undefined') {
            setErrors({ ...errors, machines: 'Veuillez remplir le formulaire correctement' })
        } else {
            let errorCopie = { ...errors }
            delete errorCopie['machines']
            setErrors({ ...errorCopie })

            setLaundry({ ...laundry }, laundry.machines.push({ id: null, name: machinesName, category: machinesCateg, detail: machinesDetail, duration: machinesDuration, minPrice: machinesMinPrice, maxPrice: machinesMaxPrice, count: machinesCount }))
            setMachinesName("")
            setMachinesCateg("")
            setMachinesDetail("")
            setMachinesMinPrice("")
            setMachinesMaxPrice("")
            setMachinesDuration("")
            setMachinesCount("")
            setCurrentMachinesName("")
            setCurrentMachinesCateg("")
            setCurrentMachinesDetail("")
            setCurrentMachinesCount("")
            setCurrentMachinesDuration("")
            setCurrentMachinesMinPrice("")
            setCurrentMachinesMaxPrice("")
            document.getElementById('machineCateg').selectedIndex = 0;
            document.getElementById('machineType').selectedIndex = 0;
        }
    }

    // initialisation des propriétés lié a l'adresse grace au donnée renvoyé par mapbox
    function setAddress(event) {
        event.features.forEach(element => {

            if (typeof element.properties.context.street !== 'undefined' & typeof element.properties.context.address !== 'undefined') {

                setLaundry({
                    ...laundry,
                    city: element.properties.context.place.name,
                    postal_code: element.properties.context.postcode.name,
                    street: element.properties.context.street.name,
                    number: element.properties.context.address.address_number,
                    latitude: element.properties.coordinates.latitude,
                    longitude: element.properties.coordinates.longitude
                })
                if (typeof errors['address'] !== 'undefined') {
                    let errorCopie = { ...errors }
                    delete errorCopie['address']
                    setErrors({ ...errorCopie })
                };

            } else {
                setErrors({ ...errors, address: 'invalid address' })

            }
        });
    }

    // controle de saisi sur l'adresse vide
    function isAddressEmpty(address) {
        if (address === '') {
            setErrors({ ...errors, address: 'Ce champ doit être rempli' })
        } else if (typeof errors['address'] !== 'undefined') {
            let errorCopie = { ...errors }
            delete errorCopie['address']
            setErrors({ ...errorCopie })
        }
        setLaundry({ ...laundry })
    }

    // fonction permettant la gestion du bouton ouvert 24h/7j
    function openAllDay() {
        if (isOpenAllDay === false) {
            setIsOpenAllDay(true)
            scheduleData.forEach(rowData => {
                handleStartHourChange(rowData.day, '00:00')
                handleEndHourChange(rowData.day, '23:59')
                handleOpenChange(rowData.day, true)
            })
        } else {
            setIsOpenAllDay(false)
            scheduleData.forEach(rowData => {
                handleStartHourChange(rowData.day, '00:00')
                handleEndHourChange(rowData.day, '23:59')
                handleOpenChange(rowData.day, false)

            })
        }
    }

    // gestion des changements d'horaire d'ouverture 
    const handleStartHourChange = (day, value) => {
        setScheduleData((prevData) =>
            prevData.map((rowData) =>
                rowData.day === day ? { ...rowData, startHour: value } : rowData
            )
        );
    };

    // gestion des changements d'horaire de fermeture 
    const handleEndHourChange = (day, value) => {
        setScheduleData((prevData) =>
            prevData.map((rowData) =>
                rowData.day === day ? { ...rowData, endHour: value } : rowData
            )
        );
    };

    // gestion des changements du booléen d'ouverture 
    const handleOpenChange = (day, value) => {
        setScheduleData((prevData) =>
            prevData.map((rowData) =>
                rowData.day === day ? { ...rowData, open: value } : rowData
            )
        );
    };

    return (
        <>
            <div className="container text-white pt-5">
                <form className="pt-4">
                    
                    <h2 className='text-center'>{location.state === undefined ? 'Ajout ': 'Édition ' }d'une laverie</h2>
                    {/* <div className=" col-1 my-auto">
                                    <button className="btn btn-primary" onClick={() => navigate('/')}>Retour</button>
                                </div> */}
                    <div className='file-upload-mobile mb-3'>
                        {!laundry.logo[0] &&
                            <div className="image-upload-wrap">
                                <input className="file-upload-input" type='file' ref={logoRef} onChange={logoChange} />
                                <div className="drag-text">
                                    <FontAwesomeIcon icon="fa-solid fa-plus" size="3x" className="mb-1" />
                                    <h3 className="fw-bold" style={{ fontSize: '10px' }}>AJOUTER LOGO</h3>
                                </div>
                            </div>
                        }

                        {laundry.logo[0] &&
                            <div>
                                <img className="my-3 border border-white border-opacity-25 ms-5" style={{ height: '125px', width: '125px', objectFit: 'contain', borderRadius: '10px', backgroundColor: '#4e4e4e' }}
                                    src={location.state === null ? preview : laundry.logo.file} />
                                <a onClick={() => removeLogo()} className='remove-logo'>
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" size="lg" color="white" />
                                </a>
                            </div>
                        }

                    </div>

                    <div>
                        {/* Présentation */}
                        <div className='mx-3 mb-3'>
                            <p className="mb-0 fw-bold fs-6">PRESENTATION</p>
                            <div className="d-flex mb-3">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            <div>
                                {laundry.description !== '' && laundry.description !== null &&
                                    <label className={errors.description ? 'error-text' : 'haller-label'} htmlFor="description">Description</label>
                                }
                                <div className="position-relative">
                                    <textarea id="description" className="form-control border-0 text-white" style={{ height: '92px', backgroundColor: '#4e4e4e' }} placeholder="Texte ..." value={laundry.description} onChange={(event) => setLaundry({ ...laundry, description: event.target.value })} />
                                    <div className="fs-sm position-absolute position-absolute bottom-0 end-0 opacity-50">
                                        <p className="mb-0 me-2">Nombre de caractères restant : {255 - (laundry.description === null ? 0 : laundry.description.length)}</p>
                                    </div>
                                </div>
                                {errors.description && (<p className="error-text ps-2">{errors.description}</p>)}
                            </div>
                        </div>

                        {/* Information */}
                        <div className='mx-3 mb-3'>
                            <p className="mb-0 fw-bold fs-6">INFORMATIONS</p>
                            <div className="d-flex mb-3">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            <div id="section-information">
                                <div>
                                    {laundry.name !== '' && laundry.name !== null &&
                                        <label className={errors.name ? 'error-text' : 'haller-label'} htmlFor="name">Nom</label>
                                    }
                                    <input type={"text"} className={errors.name ? "form-control bg-transparent border-0 text-white border-bottom error-border rounded-0" : "form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0"} placeholder="Entrez le nom" name="name" value={laundry.name} onChange={(event) => setLaundry({ ...laundry, name: event.target.value })} />
                                    {errors.name && (<p className="error-text ps-2">{errors.name}</p>)}
                                </div>
                                {fullAddress !== '' &&
                                    <label className={errors.address ? 'error-text' : 'haller-label'} htmlFor="name">Adresse</label>
                                }
                                <SearchBox
                                    accessToken={process.env.REACT_APP_ACCESS_TOKEN}
                                    value={fullAddress} placeholder="Saisissez l'adresse de la laverie"
                                    className={errors.address ? "form-control bg-transparent border-0 text-white border-bottom error-border rounded-0" : "form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0"}
                                    onRetrieve={(event) => setAddress(event)}
                                    onChange={(event) => isAddressEmpty(event)} />
                                {errors.address && (<p className="error-text ps-2">{errors.address}</p>)}
                                <div>
                                    {laundry.email !== '' && laundry.email !== null &&
                                        <label className={errors.email ? 'error-text' : 'haller-label'} htmlFor="email">Email</label>
                                    }
                                    <input id="email" type={"email"} className={errors.email ? "form-control bg-transparent border-0 text-white border-bottom error-border rounded-0" : "form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0"} placeholder="Entrez l'adresse email" name="email" value={laundry.email} onChange={(event) => setLaundry({ ...laundry, email: event.target.value })} />
                                    {errors.email && (<p className="error-text ps-2">{errors.email}</p>)}
                                </div>
                                <div>
                                    {laundry.phone_number !== '' && laundry.phone_number !== null &&
                                        <label className={errors.phone_number ? 'error-text' : 'haller-label'} htmlFor="tel">Téléphone</label>
                                    }
                                    <input id="tel" type={"tel"} className={errors.phone_number ? "form-control bg-transparent border-0 text-white border-bottom error-border rounded-0" : "form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0"} placeholder="Entrez le n° de téléphone" name="phone_number" value={laundry.phone_number} onChange={(event) => setLaundry({ ...laundry, phone_number: event.target.value })} />
                                    {errors.phone_number && (<p className="error-text ps-2">{errors.phone_number}</p>)}
                                </div>

                            </div>
                        </div>

                        {/* Horaires */}
                        <div className="mx-3 mb-3">
                            <p className="mb-0 fw-bold">HORAIRES</p>
                            <div className="d-flex">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <p className="pt-3">Ouvert 24h/7j ? Cochez cette case</p>
                                    <input type="checkbox" checked={isOpenAllDay} onClick={() => openAllDay()} readOnly />
                                </div>

                                <table className="table">
                                    <tbody>
                                        {scheduleData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td className="text-white">{rowData.day}</td>
                                                <td className="text-white">de</td>

                                                <td className="d-md-table-cell pt-1">
                                                    <input
                                                        className="form-control border-0 text-white"
                                                        style={{ background: 'var(--tertiary-color)' }}
                                                        type="time"
                                                        disabled={isOpenAllDay}
                                                        value={rowData.startHour}
                                                        onChange={(e) => handleStartHourChange(rowData.day, e.target.value)}
                                                    />
                                                </td>
                                                <td className="text-white">à</td>
                                                <td className="d-md-table-cell pt-1">
                                                    <input
                                                        className="form-control border-0 text-white"
                                                        style={{ background: 'var(--tertiary-color)' }}
                                                        type="time"
                                                        disabled={isOpenAllDay}
                                                        value={rowData.endHour}
                                                        onChange={(e) => handleEndHourChange(rowData.day, e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {errors.hours && (<p className="error-text ps-2">{errors.hours}</p>)}
                            </div>
                        </div>

                        {/* Photos */}
                        <div className='mx-3 mb-3'>
                            <p className="mb-0 fw-bold fs-6">PHOTOS</p>
                            <div className="d-flex mb-3">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            {/* Le carousel*/}
                            <div className="row mx-0">
                                <div className="mb-2 rounded-2 files-btn py-2 text-center ">
                                    <label htmlFor="files" className=" ">AJOUTER PHOTOS</label>
                                    <input id="files" type={"file"} onChange={filesChange} hidden multiple disabled={laundry.files.length > 5} />
                                </div>
                                <div className="d-flex justify-content-around p-0">
                                    {laundry.files.length !== 0 &&
                                        laundry.files.map((file, index) => (
                                            <div key={index} className="d-flex flex-column align-items-center">
                                                <img
                                                    src={location.state === null ? URL.createObjectURL(file) : file.id == undefined ? URL.createObjectURL(file) : file.content.file} alt={"Photo n°" + (index)}
                                                    className="file-object rounded-3 border border-2 border-white border-opacity-25 mb-2"
                                                />
                                                <div className="remove-file">
                                                    <a onClick={() => setLaundry({ ...laundry }, laundry.files.splice(index, 1))}>
                                                        <FontAwesomeIcon icon="fa-solid fa-xmark" size="sm" color="white" />
                                                    </a>
                                                </div>

                                            </div>
                                        ))}
                                </div>
                                {errors.files && (<p className="error-text ps-2">{errors.files}</p>)}
                            </div>
                        </div>

                        {/* Paiements et services */}
                        <div className='mx-3 mb-3'>
                            <p className="mb-0 fw-bold fs-6">PAIMENTS ET SERVICES</p>
                            <div className="d-flex mb-3">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            <div>
                                <p className="mb-4">Sélectionnez les paiements et services disponible dans votre laverie</p>
                                <div className="mx-2">
                                    <div className="row">
                                        {payments.map((payment, index) => {
                                            return (
                                                <div key={index} className="col-2 d-flex flex-column align-items-center " >
                                                    <div className={payment.isActive ? "rounded-2 d-flex py-2 justify-content-center selected-utils" : "rounded-2 d-flex py-2 justify-content-center unselected-utils"} onClick={() => setActivePayment(payment.value)}>
                                                        <FontAwesomeIcon icon={payment.icon} size={'lg'} />
                                                    </div>
                                                    <p className="fs-icon-label">{payment.label}</p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div className="title-decoration-2 mb-3 w-100" />
                                    <div className="row">
                                        {equipements.map((equipement, index) => {
                                            return (
                                                <div key={index} className="col-2 d-flex flex-column align-items-center " >
                                                    <div className={equipement.isActive ? "rounded-2 d-flex py-2 justify-content-center selected-utils" : "rounded-2 d-flex py-2 justify-content-center unselected-utils"} onClick={() => setActiveEquipement(equipement.value)}>
                                                        <FontAwesomeIcon icon={equipement.icon} size={'lg'} />
                                                    </div>
                                                    <p className="fs-icon-label">{equipement.label}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Machines */}
                        <div className='mx-3 mb-3'>
                            <p className="mb-0 fw-bold fs-6">MACHINES</p>
                            <div className="d-flex mb-3">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            <div>
                                {laundry.machines.map((machine, index) => {
                                    return (
                                        <div key={index} className='card mb-3 text-black'>
                                            <div className='card-body p-0'>
                                                <div className='row'>
                                                    <div className='col-4 d-flex align-items-center'>
                                                        <img src={
                                                            machine.category === 'Machines' ?
                                                                WashingMachine :
                                                                machine.category === 'Sèche-linge' ?
                                                                    DryerLogo :
                                                                    machine.category === 'Produit' ? ProductLogo :
                                                                        OthersLogo
                                                        }
                                                            alt={laundry.name} style={{ width: '5rem' }} />
                                                    </div>
                                                    <div className='fs-xs lh-sm col-8 ps-0'>
                                                        <div className="d-flex align-items-center">
                                                            <p className='fs-6 my-2 ps-1 col-10'><strong>{machine.name}</strong></p>
                                                            <button type="button" className="p-1  border-0" onClick={() => setLaundry({ ...laundry }, laundry.machines.splice(index, 1))}>
                                                                <FontAwesomeIcon icon="fa-regular fa-trash-can" size="2x" />
                                                            </button>
                                                        </div>
                                                        <div className='row'>
                                                            {(machine.category === 'Machines' || machine.category === 'Sèche-linge') &&
                                                                <>
                                                                    {machine.detail &&
                                                                        <div className='d-flex col-6 pe-0 ps-1 mb-1'>
                                                                            <FontAwesomeIcon icon="fa-solid fa-up-right-and-down-left-from-center" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                                            <p className='ms-2 mb-0 fw-bold'>TAILLE : {machine.detail}</p>
                                                                        </div>
                                                                    }
                                                                    {machine.duration &&
                                                                        <div className='d-flex col-6 ps-0'>
                                                                            <FontAwesomeIcon icon="fa-regular fa-clock" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                                            <p className='ms-2 mb-0 fw-bold'>DURÉE : {machine.duration}</p>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                            {machine.count &&
                                                                <div className='d-flex col-6 pe-0 ps-1'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-hashtag" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                                    <p className='ms-2 mb-0 fw-bold'>NOMBRE : {machine.count}</p>
                                                                </div>
                                                            }
                                                            <div className='d-flex col-6 ps-0'>
                                                                <FontAwesomeIcon icon="fa-solid fa-euro-sign" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                                {machine.maxPrice === machine.minPrice ?
                                                                    <p className='ms-2 mb-0 fw-bold'>PRIX : {machine.minPrice}€</p>
                                                                    : <p className='ms-2 mb-0 fw-bold'>PRIX : {machine.minPrice}€ et {machine.maxPrice}€</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='card mb-3 text-black'>
                                <div className='card-body p-0'>
                                    <div className='row'>
                                        <div className='col-4 d-flex align-items-center'>
                                            <img src={
                                                currentMachinesCateg === 'Machines' ?
                                                    WashingMachine :
                                                    currentMachinesCateg === 'Sèche-linge' ?
                                                        DryerLogo :
                                                        currentMachinesCateg === 'Produit' ? ProductLogo :
                                                            OthersLogo
                                            }
                                                alt={laundry.name} style={{ width: '5rem' }} />
                                        </div>
                                        <div className='fs-xs lh-sm col-8 ps-0'>
                                            <div className="d-flex align-items-center">
                                                <p className='fs-6 my-2 ps-1 col-10'><strong>{currentMachinesName}</strong></p>
                                            </div>
                                            <div className='row'>
                                                {(currentMachinesCateg === 'Machines' || currentMachinesCateg === 'Sèche-linge') &&
                                                    <>
                                                        {currentMachinesDetail &&
                                                            <div className='d-flex col-6 pe-0 ps-1 mb-1'>
                                                                <FontAwesomeIcon icon="fa-solid fa-up-right-and-down-left-from-center" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                                <p className='ms-2 mb-0 fw-bold'>TAILLE : {currentMachinesDetail}</p>
                                                            </div>
                                                        }
                                                        {currentMachinesDuration &&
                                                            <div className='d-flex col-6 ps-0'>
                                                                <FontAwesomeIcon icon="fa-regular fa-clock" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                                <p className='ms-2 mb-0 fw-bold'>DURÉE : {currentMachinesDuration}</p>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {currentMachinesCount &&
                                                    <div className='d-flex col-6 pe-0 ps-1'>
                                                        <FontAwesomeIcon icon="fa-solid fa-hashtag" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                        <p className='ms-2 mb-0 fw-bold'>NOMBRE : {currentMachinesCount}</p>
                                                    </div>
                                                }
                                                <div className='d-flex col-6 ps-0'>
                                                    <FontAwesomeIcon icon="fa-solid fa-euro-sign" style={{ color: 'var(--primary-color)' }} size={'lg'} />
                                                    {currentMachinesMaxPrice === currentMachinesMinPrice ?
                                                        <p className='ms-2 mb-0 fw-bold'>PRIX : {currentMachinesMinPrice}€</p>
                                                        : <p className='ms-2 mb-0 fw-bold'>PRIX : {currentMachinesMinPrice}€  et  {currentMachinesMaxPrice}€</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <input name="machinesName" className="form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0" placeholder="Nom de la machines..." value={machinesName} onChange={(e) => (setMachinesName(e.target.value), setCurrentMachinesName(e.target.value))} />
                                <div className="border-bottom rounded-0 d-flex mb-3">
                                    <select id="machineCateg" scalar={machinesCateg} onChange={(e) => (setMachinesCateg(e.target.value), setCurrentMachinesCateg(e.target.value))} className="form-control bg-transparent border-0 text-white">
                                        <option className="text-black" selected disabled>Choisissez votre Catégorie</option>
                                        <option className="text-black">Machines</option>
                                        <option className="text-black">Sèche-linge</option>
                                        <option className="text-black">Produit</option>
                                        <option className="text-black">Autre</option>
                                    </select>
                                    <div className="pt-2 pe-3">
                                        <FontAwesomeIcon icon="fa-solid fa-sort-down" />
                                    </div>
                                </div>
                                <div className="border-bottom rounded-0 d-flex mb-3">
                                    <select id="machineType" scalar={machinesDetail} onChange={(e) => (setMachinesDetail(e.target.value), setCurrentMachinesDetail(e.target.value))} className="form-control bg-transparent border-0 text-white">
                                        <option className="text-black" selected disabled>Choisissez votre type</option>
                                        <option className="text-black">Petit</option>
                                        <option className="text-black">Moyen</option>
                                        <option className="text-black">Gros</option>
                                        <option className="text-black">Enorme</option>
                                    </select>
                                    <div className="pt-2 pe-3">
                                        <FontAwesomeIcon icon="fa-solid fa-sort-down" />
                                    </div>
                                </div>
                                <input name="Duration" type="time" className="form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0"
                                    style={{ width: '-webkit-fill-available' }}
                                    value={machinesDuration} onChange={(e) => (setMachinesDuration(e.target.value), setCurrentMachinesDuration(e.target.value))} />
                                <input name="MinPrice" className="form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0" placeholder="Prix minimum de la machines..." value={machinesMinPrice} onChange={(e) => (setMachinesMinPrice(e.target.value), setCurrentMachinesMinPrice(e.target.value))} />
                                {errors.machinesMinPrice && (<p className="text-danger">{errors.machinesMinPrice}</p>)}
                                <input name="MaxPrice" className="form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0" placeholder="Prix maximum de la machines..." value={machinesMaxPrice} onChange={(e) => (setMachinesMaxPrice(e.target.value), setCurrentMachinesMaxPrice(e.target.value))} />
                                {errors.machinesMaxPrice && (<p className="text-danger">{errors.machinesMaxPrice}</p>)}
                                <input name="dispoNbr" className="form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0" placeholder="Nombre de machines..." value={machinesCount} onChange={(e) => (setMachinesCount(e.target.value), setCurrentMachinesCount(e.target.value))} />
                                {errors.machinesCount && (<p className="text-danger">{errors.machinesCount}</p>)}
                                <button type="button" className="btn btn-primary mx-auto rounded-5 px-4 border-0" style={{ width: 'fit-content', backgroundColor: 'var(--tertiary-color)' }} onClick={() => addNewMachines()}>
                                    {laundry.machines.length !== 0 ? "AJOUTER UNE AUTRE MACHINE" : "AJOUTER UNE MACHINE"}
                                </button>
                                {errors.machines && (<p className="text-danger">{errors.machines}</p>)}
                            </div>
                        </div>

                        {/* Réseaux sociaux */}
                        <div className='mx-3 mb-3'>
                            <p className="mb-0 fw-bold fs-6">RÉSEAUX SOCIAUX</p>
                            <div className="d-flex mb-3">
                                <div className="title-decoration-1" />
                                <div className="title-decoration-2 align-self-end" />
                            </div>
                            <div>
                                {laundry.links.map((link, index) => (
                                    <div key={index} className="card mb-3">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div>
                                                {link.type === "Instagram" ? <FontAwesomeIcon icon="fa-brands fa-instagram" size="2x" className="text-black" /> : ''}
                                                {link.type === "Facebook" ? <FontAwesomeIcon icon="fa-brands fa-facebook" size="2x" className="text-black" /> : ''}
                                                {link.type === "Site Personnel" ? <FontAwesomeIcon icon="fa-solid fa-globe" size="2x" className="text-black" /> : ''}
                                            </div>
                                            <a href={link.link} target='blank' className="text-black mb-0 text-decoration-none">{
                                                link.link !== '' ?
                                                    /^http/i.test(link.link) ?
                                                        link.type !== "Site Personnel" ?
                                                            link.link.split('/')[3].split('?')[0] : link.link.split('.')[1] : link.link.substr(0, 15) + '...' : link.link.substr(0, 15) + '...'
                                            }</a>
                                            <button type="button" className="btn btn-danger px-2 py-0 rounded-5" onClick={() => setLaundry({ ...laundry }, laundry.links.splice(index, 1))}>X</button>

                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex flex-column">
                                <input value={link} className='form-control mb-3 bg-transparent border-0 text-white border-bottom rounded-0' onChange={(e) => setLink(e.target.value)} placeholder="Lien" />
                                <div className="border-bottom rounded-0 d-flex mb-3">
                                    <select value={type} onChange={(e) => setType(e.target.value)} className="form-control bg-transparent border-0 text-white rounded-0">
                                        <option className="text-black" value='' selected disabled>Choisissez votre réseau social</option>
                                        <option className="text-black" value="Site Personnel">Site personnel</option>
                                        <option className="text-black" value="Instagram">Instagram</option>
                                        <option className="text-black" value="Facebook">Facebook</option>
                                    </select>
                                    <div className="pt-2 pe-3">
                                        <FontAwesomeIcon icon="fa-solid fa-sort-down" />
                                    </div>
                                </div>
                                {errors.links && (<p className="error-text ps-2">{errors.links}</p>)}
                                <button type="button" className="btn btn-primary mx-auto rounded-5 px-4 border-0" style={{ width: 'fit-content', backgroundColor: 'var(--tertiary-color)' }} onClick={() => addNewLink()}>
                                    {laundry.links.length !== 0 ? "AJOUTER UN AUTRE LIEN" : "AJOUTER UN LIEN"}
                                </button>
                            </div>
                        </div>

                        <hr className="w-75 mx-auto" />

                        <div className='d-flex'>
                            <button className="btn btn-primary mx-auto rounded-5 px-4 border-0" style={{ width: 'fit-content', backgroundColor: 'var(--primary-color)' }} type={"button"} onClick={() => saveLaundry()}>VALIDER</button>
                            {!isValidate && (<p className="text-danger mb-0">Certains champs ont été mal remplis</p>)}
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
}

export default LaundriesForm